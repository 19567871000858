import React, { useState, useCallback } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Steps from "react-steps";

import ModalOcorrencia from "../ModalOcorrencia";

const CardResult = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [idNotaFiscal, setIdNotaFiscal] = useState(null);

  const handleToggleModal = useCallback(
    (e, notaFiscal) => {
      if (notaFiscal) {
        setIdNotaFiscal(notaFiscal);
      }
      setModal(!modal);
    },
    [modal]
  );

  const renderItensSteps = (item) => [
    {
      text: `Data Emissão: ${item.dt_emissao ? item.dt_emissao : "-"}`,
      isActive: item.dt_emissao ? false : true,
      isDone: item.dt_emissao ? true : false,
    },
    {
      text: `Data Prevista: ${
        item.dt_previsao_entrega ? item.dt_previsao_entrega : "-"
      }`,
      isActive: item.dt_previsao_entrega ? false : true,
      isDone: item.dt_previsao_entrega ? true : false,
    },
    {
      text: `Data Entrega: ${item.dt_entrega ? item.dt_entrega : "-"}`,
      isActive: item.dt_entrega ? false : true,
      isDone: item.dt_entrega ? true : false,
    },
  ];

  return (
    <>
      <ModalOcorrencia
        modal={modal}
        toggle={handleToggleModal}
        notaFiscal={idNotaFiscal}
      />
      {data.map((item, index) => (
        <Col key={index}>
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h6>N° Rastreio</h6>
                    <p>{item.id}</p>
                  </Col>
                  <Col>
                    <h6>Nota Fiscal</h6>
                    <p>{item.numero}</p>
                  </Col>
                  <Col>
                    <h6>N° Pedido</h6>
                    <p>{item.numero_pedido}</p>
                  </Col>
                  <Col>
                    <h6>Chave de Acesso NFe</h6>
                    <p>{item.chave_acesso}</p>
                  </Col>
                  <Col>
                    <h6>Remetente</h6>
                    <p>{item.remetente}</p>
                  </Col>
                  <Col>
                    <h6>Destinatário</h6>
                    <p>{item.destinatario}</p>
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      color="info"
                      onClick={(e) => handleToggleModal(e, item.id)}
                    >
                      <i className="tim-icons icon-delivery-fast" />
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                    <h6>Entrega</h6>
                    <Steps items={renderItensSteps(item)} type={"point"} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Col>
      ))}
    </>
  );
};

export default CardResult;
