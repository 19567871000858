import React, { useCallback, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Input,
  Form,
  CardTitle,
} from "reactstrap";

import ReactDatetime from "react-datetime";
import Select from "react-select";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import CustomTable from "components/CustomTable";

const Pedido = () => {
  const [tipoPedido, setTipoPedido] = useState(null);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [loading] = useState(false);

  const data = [
    {
      id: 1,
      numero: 123456,
      status: "aberto",
      destinatario: "Empresa A",
      cidadeDestino: "Santos",
      ufDestino: "SP",
      notaFiscal: 789654,
      transportadora: "Transporte de cargas",
      tipoServico: "venda",
      dataEmbarque: "10/10/2020",
      dataPrevEntrega: "20/10/2020",
    },
    {
      id: 2,
      numero: 4567897,
      status: "aberto",
      destinatario: "Empresa A",
      cidadeDestino: "Indaiatuba",
      ufDestino: "SP",
      notaFiscal: 784653213,
      transportadora: "Transporte de cargas",
      tipoServico: "venda",
      dataEmbarque: "10/10/2020",
      dataPrevEntrega: "20/10/2020",
    },
    {
      id: 3,
      numero: 55687878,
      status: "aberto",
      destinatario: "Empresa A",
      cidadeDestino: "Campinas",
      ufDestino: "SP",
      notaFiscal: 4564287784,
      transportadora: "Transporte de cargas",
      tipoServico: "venda",
      dataEmbarque: "10/10/2020",
      dataPrevEntrega: "20/10/2020",
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "numero",
      text: "Número",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "destinatario",
      text: "Destinatário",
    },
    {
      dataField: "cidadeDestino",
      text: "Destino",
    },
    {
      dataField: "ufDestino",
      text: "UF",
    },
    {
      dataField: "notaFiscal",
      text: "Nota Fiscal",
    },
    {
      dataField: "transportadora",
      text: "Transportadora",
    },
    {
      dataField: "tipoServico",
      text: "Tipo",
    },
    {
      dataField: "dataEmbarque",
      text: "Embarque",
    },
    {
      dataField: "dataPrevEntrega",
      text: "Previsão Entrega",
    },
  ];

  const handleFilter = useCallback(() => {
    setFilterIsActive(!filterIsActive);
  }, [filterIsActive]);

  return (
    <div className="content">
      <Row>
        <Col className="mb-2">
          <Button color="info">Criar</Button>
          <Button
            className="btn-icon"
            color={filterIsActive ? "success" : "danger"}
            onClick={handleFilter}
          >
            {filterIsActive ? <RiFilterFill /> : <RiFilterOffFill />}
          </Button>
        </Col>
      </Row>
      <Row>
        {filterIsActive ? (
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Filtros</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label md="1">Emissão(90d)</Label>
                  <Col md="3">
                    <FormGroup>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "data inicial",
                          style: { color: "#222a42", textAlign: "center" },
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                        }}
                        onChange={() => {}}
                        isValidDate={() => {}}
                        // value={}
                        timeFormat={false}
                        closeOnSelect
                        locale="pt-br"
                      />
                    </FormGroup>
                  </Col>
                  <p style={{ display: "flex", alignItems: "center" }} md="1">
                    até
                  </p>
                  <Col md="3">
                    <FormGroup>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "data final",
                          style: { color: "#222a42", textAlign: "center" },
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                        }}
                        onChange={() => {}}
                        isValidDate={() => {}}
                        // value={}
                        timeFormat={false}
                        closeOnSelect
                        locale="pt-br"
                      />
                    </FormGroup>
                  </Col>
                  <Label md="2">Tipo de pedido</Label>
                  <Col md="2">
                    <FormGroup>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="tipoPedido"
                        value={tipoPedido}
                        onChange={(value) => setTipoPedido(value)}
                        options={[
                          { value: "1", label: "Venda" },
                          { value: "2", label: "Compra" },
                        ]}
                        placeholder="Escolha um tipo"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label md="1">N° nota fiscal</Label>
                  <Col md="2">
                    <FormGroup>
                      <Input type="text" />
                    </FormGroup>
                  </Col>
                  <Label md="1">Nº Pedido</Label>
                  <Col md="2">
                    <FormGroup>
                      <Input type="text" />
                    </FormGroup>
                  </Col>
                  <Label md="2">Chave de acesso</Label>
                  <Col md="3">
                    <FormGroup>
                      <Input type="text" />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        ) : null}
        <Card>
          <CardBody>
            <CustomTable
              data={data}
              columns={columns}
              fileName="pedidos"
              tableName="Pedidos"
              loading={loading}
            />
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};
export default Pedido;
