import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import ReactTable from "react-table";
import { ClipLoader } from "react-spinners";
import _ from "lodash";

import api from "../../../services/api";

import { useCompanies } from "hooks/companies";

import { ContainerButtonsModal } from "./styles";

const route = "/integracoes/credenciais";
const routeprovedores = "/integracoes/provedores";
const routeuploadimagem = "/integracoes/upload-logo-rastreio";
const title = "Integração Ecommerce";

const EcommerceIntegration = () => {
  const { empresa } = useCompanies();
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [dataprovedor, setDataprovedor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: 0,
    embarcadorId: 0,
    apiUrl: "",
    apiKey: "",
    apiSecret: "",
    provedor: "",
  });

  const [newAlert, setNewAlert] = useState({
    text: "",
    open: false,
    type: "info",
  });

  const [file, setFile] = useState();

  const handleUpload = (event) => {
    const fileSended = event.target.files[0];

    const size = parseInt(fileSended.size);
    if (size > 3145728) {
      //MAX_FILE_SIZE = 3097152 Bytes
      openAlert("Tamanho do arquivo excede o permitido (3MB)!", "danger");
      return;
    }

    const accepts = ["image/png", "image/jpg"];

    if (accepts.includes(fileSended.type)) {
      setFile(fileSended);
    } else {
      openAlert(`Formato não aceito, envie apenas .png ou .jpg`, "danger");
    }
  };

  const onUploadFile = () => {
    if (!file) {
      return false;
    }
    const formData = new FormData();

    formData.append("logo-rastreio", file);

    api
      .post(`${routeuploadimagem}/${empresa.value}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((resp) => {
        console.log("Imagem enviada com sucesso!");
      })
      .catch((err) => {
        openAlert("Erro ao enviar imagem", "danger");
      });
  };

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const toggleDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  const colunms = [
    {
      Header: "id",
      accessor: "id",
      headerClassName: "text-center",
    },
    {
      Header: "apiUrl",
      accessor: "apiUrl",
      headerClassName: "text-center",
    },
    {
      Header: "apiKey",
      accessor: "apiKey",
      headerClassName: "text-center",
    },
    {
      Header: "apiSecret",
      accessor: "apiSecret",
      headerClassName: "text-center",
    },
    {
      Header: "provedor",
      accessor: "provedor.nome",
      headerClassName: "text-center",
    },
    {
      Header: "logo",
      accessor: "logo",
      headerClassName: "text-center",
      Cell: (row) => {
        const logo = row.original.logoRastreio;
        if (logo !== null) {
          return (
            <a className="text-info" href={logo}>
              download logo
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => (
        <div>
          <button
            className="btn-icon btn-round btn-success"
            style={{ margin: "3px" }}
            onClick={() => {
              setEdit(true);
              setState({
                ...state,
                id: row.original.id,
                embarcadorId: row.original.embarcadorId,
                apiUrl: row.original.apiUrl,
                apiKey: row.original.apiKey,
                apiSecret: row.original.apiSecret,
                provedor: row.original.provedor,
              });
              toggle();
            }}
          >
            <i className="tim-icons icon-pencil" />
          </button>
          <button
            className="btn-icon btn-round btn-danger"
            style={{ margin: "3px" }}
            onClick={() => {
              setState({ ...state, id: row.original.id });
              toggleDelete();
            }}
          >
            <i className="tim-icons icon-trash-simple" />
          </button>
        </div>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const openAlert = (text = "", type = "info") => {
    setNewAlert({
      text: text,
      open: !newAlert.open,
      type: type,
    });
  };

  const editUser = () => {
    if (!state.apiUrl) {
      openAlert("ApiUrl é obrigatorio(a)", "danger");
    } else if (!state.apiKey) {
      openAlert("ApiKey é obrigatório(a)", "danger");
    } else if (!state.apiSecret) {
      openAlert("ApiSecret é obrigatório(a)", "danger");
    } else if (!state.provedor) {
      openAlert("provedor é obrigatório(a)", "danger");
    } else {
      api
        .put(`${route}/${state.id}`, {
          apiUrl: state.apiUrl,
          apiKey: state.apiKey,
          apiSecret: state.apiSecret,
          provedor: state.provedor,
        })
        .then(() => {
          onUploadFile();
          setState({
            id: 0,
            embarcadorId: 0,
            apiUrl: "",
            apiKey: "",
            apiSecret: "",
            provedor: "",
          });
          openAlert("Alterado com sucesso!", "success");
          toggle();
          getAll();
        })
        .catch((error) => {
          openAlert(`Error: ${error}`, "danger");
        });
    }
    //  if (!nomeFantasia) {
    //    openAlert("O nome fantasia é obrigatorio", "danger");
    //  } else if (!cnpj) {
    //    openAlert("O cnpj é obrigatório", "danger");
    //  } else if (!cep) {
    //    openAlert("O cep é obrigatório", "danger");
    //  } else {
    //    apiPortalTransportador
    //      .put("/Companies", {
    //        socialName: razaoSocial,
    //        fantasyName: nomeFantasia,
    //        telephone: telefone,
    //        cellPhone: celular,
    //        city: cidade,
    //        zip_Code: cep,
    //        address: logradouro,
    //        number: numero,
    //        complement: complemento,
    //        neighborhood: bairro,
    //        cnpj: cnpj,
    //        idCompany: id,
    //      })
    //      .then(() => {
    //        setRazaoSocial("");
    //        setNomeFantasia("");
    //        setTelefone("");
    //        setCelular("");
    //        setCidade("");
    //        setCnpj("");
    //        setLogradouro("");
    //        setNumero("");
    //        setComplemento("");
    //        setBairro("");
    //        setCnpj("");
    //        setId("");
    //        setEdit(false);
    //        getAllCompanies();
    //        openAlert("Usuário alterado com sucesso!", "success");
    //        toggle();
    //      });
    //  }
  };

  const create = () => {
    if (!state.apiUrl) {
      openAlert("ApiUrl é obrigatorio(a)", "danger");
    } else if (!state.apiKey) {
      openAlert("ApiKey é obrigatório(a)", "danger");
    } else if (!state.apiSecret) {
      openAlert("ApiSecret é obrigatório(a)", "danger");
    } else if (!state.provedor) {
      openAlert("provedor é obrigatório(a)", "danger");
    } else {
      api
        .post(route, {
          embarcadorId: empresa.value,
          apiUrl: state.apiUrl,
          apiKey: state.apiKey,
          apiSecret: state.apiSecret,
          provedor: state.provedor,
        })
        .then(() => {
          setState({
            id: 0,
            embarcadorId: 0,
            apiUrl: "",
            apiKey: "",
            apiSecret: "",
            provedor: "",
          });
          onUploadFile();
          openAlert("Criado(a) com sucesso!", "success");
          toggle();
          getAll();
        })
        .catch((error) => {
          openAlert(`Error: ${error}`, "danger");
        });
    }
  };

  const remove = () => {
    api
      .delete(`${route}/${state.id}`)
      .then(() => {
        setState({
          id: 0,
          embarcadorId: 0,
          apiUrl: "",
          apiKey: "",
          apiSecret: "",
          provedor: "",
        });
        openAlert("Deletada(o) com sucesso!", "success");
        toggleDelete();
        getAll();
      })
      .catch((error) => {
        openAlert(`Error: ${error}`, "danger");
      });
  };

  const getAll = async () => {
    try {
      setLoading(true);
      const result = await api.get(route, {
        params: { embarcadorId: empresa.value },
      });
      if (result.status === 200) {
        setData(result.data);
      }
    } catch (error) {
      openAlert(`Error: ${error}`, "danger");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getAllprovedores = async () => {
    try {
      setLoading(true);
      const result = await api.get(routeprovedores);
      if (result.status === 200) {
        setDataprovedor(result.data);
      }
    } catch (error) {
      openAlert(`Error: ${error}`, "danger");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllprovedores();
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  return (
    <div className="content">
      <div className="rna-container">
        <Alert
          color={newAlert.type}
          isOpen={newAlert.open}
          toggle={() => openAlert("", "")}
        >
          {newAlert.text}
        </Alert>
      </div>

      <Modal isOpen={openModalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>
          <h2 className="text-dark">{title}</h2>
        </ModalHeader>
        <ModalBody>
          <h2 className="text-dark">Você realmente quer excluir?</h2>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              className="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={toggleDelete}
            >
              Não
            </button>
            <button
              type="button"
              className="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={remove}
            >
              Sim
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2 className="text-dark">
            {edit ? "Editar" : "Nova"} {title}
          </h2>
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label for="apiUrl">apiUrl*</label>
            <input
              autoComplete="off"
              className="form-control text-dark"
              id="apiUrl"
              value={state.apiUrl}
              onChange={(e) => setState({ ...state, apiUrl: e.target.value })}
              placeholder="apiUrl"
            />
          </div>
          <div className="form-group">
            <label for="apiKey">apiKey*</label>
            <input
              autoComplete="off"
              className="form-control text-dark"
              id="apiKey"
              value={state.apiKey}
              onChange={(e) => setState({ ...state, apiKey: e.target.value })}
              placeholder="apiKey"
            />
          </div>
          <div className="form-group">
            <label for="apiSecret">apiSecret*</label>
            <input
              autoComplete="off"
              className="form-control text-dark"
              id="apiSecret"
              value={state.apiSecret}
              onChange={(e) =>
                setState({ ...state, apiSecret: e.target.value })
              }
              placeholder="apiSecret"
            />
          </div>
          <div className="form-group">
            <label for="provedor">provedor</label>
            <select
              className="form-control text-dark"
              name="select"
              id="type"
              value={state.provedor}
              onChange={(e) => setState({ ...state, provedor: e.target.value })}
            >
              <option className="form-control text-dark" disabled value="">
                Selecione
              </option>
              {dataprovedor.map((item) => (
                <option
                  key={item.value}
                  className="form-control text-dark"
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label htmlFor="upload-image">Logo | 3mb .png .jpg</label>
            <input
              id="upload-image"
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => handleUpload(e)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              className="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={edit ? () => editUser() : () => create()}
            >
              Salvar
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Row>
        <Col className="ml-auto mr-auto">
          <h2 className="text-center">{title}</h2>
        </Col>
      </Row>
      <Row>
        {_.isEmpty(data) ? (
          <Col className="ml-auto mr-auto">
            <button
              type="button"
              className="btn btn-primary btn-round"
              onClick={toggle}
            >
              Novo
            </button>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Card>
          <CardBody className="text-center">
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <ClipLoader
                  sizeUnit="px"
                  size={90}
                  color="#1d8cf8"
                  loading={loading}
                />
                <br />
                <br />
                <h1 className="text-info" style={{ textAlign: "center" }}>
                  Carregando ...
                </h1>
              </div>
            ) : (
              <ReactTable
                data={data}
                resizable={true}
                columns={colunms}
                defaultPageSize={5}
                showPaginationBottom
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado a ser exibido"
                pageText="Página"
                ofText="do"
                rowsText="linhas"
                rowsSelectorText="linhas por página"
                className="-striped -highlight"
              />
            )}
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default EcommerceIntegration;
