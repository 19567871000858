import React from "react";
import { Row, Col, CardTitle } from "reactstrap";
import { ClipLoader } from "react-spinners";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

const CustomTable = ({ data, columns, fileName, tableName, loading }) => {
  const { ExportCSVButton } = CSVExport;
  const { SearchBar, ClearSearchButton } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} resultados
    </span>
  );

  const paginationOptions = {
    sizePerPage: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
    firstPageText: "Primeira",
    prePageText: "Voltar",
    nextPageText: "Proxímo",
    lastPageText: "Última",
    nextPageTitle: "Primeira página",
    prePageTitle: "Pré página",
    firstPageTitle: "Próxima página",
    lastPageTitle: "Última página",
    showTotal: true,
    paginationTotalRenderer: customTotal,
  };

  const csvOptions = {
    onlyExportFiltered: true,
    exportAll: true,
    fileName,
  };

  const orderIcon = (order) => {
    if (!order)
      return (
        <span>
          &nbsp;&nbsp;
          <i className="tim-icons icon-minimal-down" />
          <i className="tim-icons icon-minimal-up" />
        </span>
      );
    if (order === "asc")
      return (
        <span>
          &nbsp;
          <font color="red">
            <i className="tim-icons icon-minimal-up" />
          </font>
        </span>
      );
    if (order === "desc")
      return (
        <span>
          &nbsp;
          <font color="red">
            <i className="tim-icons icon-minimal-down" />
          </font>
        </span>
      );
    return null;
  };

  const columnsFormatted = columns.map((col) => ({
    dataField: col.dataField,
    text: col.text,
    sort: true,
    hidden: col.dataField === "id",
    sortCaret: (order, column) => {
      return orderIcon(order, column);
    },
  }));

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader sizeUnit="px" size={90} color="#1d8cf8" loading={loading} />
        <br />
        <br />
        <h1 className="text-info" style={{ textAlign: "center" }}>
          Carregando ...
        </h1>
      </div>
    );
  }
  return (
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columnsFormatted}
      exportCSV={csvOptions}
      search={{ searchFormatted: true }}
    >
      {(prop) => (
        <>
          <Row style={{ alignItems: "center" }}>
            <Col md={3}>
              <CardTitle tag="h4">
                <i className="tim-icons icon-paper" /> {tableName}
              </CardTitle>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <SearchBar {...prop.searchProps} />
              <ClearSearchButton
                className="btn btn-danger"
                {...prop.searchProps}
              />
              <ExportCSVButton className="btn btn-success" {...prop.csvProps}>
                Exportar CSV
              </ExportCSVButton>
            </Col>
          </Row>
          <hr />
          <BootstrapTable
            {...prop.baseProps}
            noDataIndication="Consulta não retornou dados, altere a consulta para obter um novo resultado."
            bordered={false}
            striped
            classes="text-center"
            pagination={paginationFactory(paginationOptions)}
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default CustomTable;
