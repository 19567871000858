import React, { useCallback, useRef } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { Form } from "@unform/web";
import * as Yup from "yup";
import imgLogin from "../../../assets/img/login.png";
import getValidationErrors from "../../../utils/getValidationErrors";
import CustomInputGroupText from "components/CustomInputGroupText";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";

const Login = () => {
  const formRef = useRef(null);
  const alertRef = useRef();
  const { login } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          username: Yup.string().required("Username é obrigatório"),
          password: Yup.string().required("Senha é obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await login(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current.setErrors(errors);
          return;
        }
        const message = {
          place: "tr",
          type: "danger",
          title: "Error",
          description:
            "Autenticação não autorizada, verifique suas credenciais!",
          icon: "tim-icons icon-alert-circle-exc",
        };
        addToast(message);
      }
    },
    [addToast, login]
  );

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Card className="card-white">
                <CardHeader>
                  <img alt="..." src={imgLogin} />
                </CardHeader>
                <CardBody>
                  <CustomInputGroupText
                    name="username"
                    placeholder="Username"
                    type="text"
                    addonType="prepend"
                    icon="tim-icons icon-single-02"
                  />
                  <CustomInputGroupText
                    name="password"
                    type="password"
                    placeholder="Senha"
                    addonType="prepend"
                    icon="tim-icons icon-lock-circle"
                  />
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="info"
                    size="lg"
                    type="submit"
                  >
                    Entrar
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
