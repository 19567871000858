import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import api from "../../../../../services/api";
import { useTheme } from "../../../../../hooks/theme";
import { formatCurrency } from "../../../../../utils/formatCurrency";

export default function ChartFreteAdicional() {
  const { darkMode } = useTheme();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const color = darkMode ? "#e9ecef" : "#1e1e2f";

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const response = await api.get(
          "/dashboard/performance/frete-adicional"
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, []);

  const CustomTooltip = (props) => {
    const { active } = props;
    if (active) {
      const { payload } = props;
      const payFormatted = payload.map((pay) => ({
        name: pay.name,
        value: formatCurrency(pay.value),
      }));
      return (
        <div
          className="tooltip-inner"
          style={{
            background: color,
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].name}: `}</strong>
            {payFormatted[0].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[1].name}: `}</strong>
            {payFormatted[1].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[2].name}: `}</strong>
            {payFormatted[2].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[3].name}: `}</strong>
            {payFormatted[3].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[4].name}: `}</strong>
            {payFormatted[4].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[5].name}: `}</strong>
            {payFormatted[5].value}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[6].name}: `}</strong>
            {payFormatted[6].value}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
      <CardHeader>
        <h4>Frete Adicional</h4>
        <CardTitle>
          Valor em reais de frete adicional apresentados por mês
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            <AreaChart
              data={data}
              margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
            >
              <defs>
                <linearGradient
                  id="valorAtendimento"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="20%" stopColor="#fb6340" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#fb6340" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#fb6340" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="valorDevolucao" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#5603ad" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#5603ad" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#5603ad" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="valorEntrega" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#1d8cf8" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#1d8cf8" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#1d8cf8" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient
                  id="valorReclamacao"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="20%" stopColor="#2dce89" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#2dce89" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#2dce89" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="valorReentrega" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#FFBB28" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#FFBB28" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#FFBB28" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="valorRetencao" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#f5365c" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#f5365c" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#f5365c" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="valorSinistro" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#e14eca" stopOpacity={0.3} />
                  <stop offset="90%" stopColor="#e14eca" stopOpacity={0.1} />
                  <stop offset="90%" stopColor="#e14eca" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="periodo"
                stroke={color}
                style={{ fontWeight: 100 }}
              />
              <YAxis
                tickFormatter={(tick) => formatCurrency(tick)}
                stroke={color}
                style={{ fontWeight: 100 }}
                width={100}
              />
              <CartesianGrid stroke="rgba(251,99,64, 0.1)" strokeWidth={1} />
              <Tooltip content={(props) => CustomTooltip(props)} />
              <Legend />
              <Area
                name="atendimento"
                type="monotone"
                dataKey="valorAtendimento"
                stroke="#fb6340"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorAtendimento)"
              />
              <Area
                name="devolucao"
                type="monotone"
                dataKey="valorDevolucao"
                stroke="#5603ad"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorDevolucao)"
              />
              <Area
                name="entrega"
                type="monotone"
                dataKey="valorEntrega"
                stroke="#1d8cf8"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorEntrega)"
              />
              <Area
                name="reclamacao"
                type="monotone"
                dataKey="valorReclamacao"
                stroke="#2dce89"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorReclamacao)"
              />
              <Area
                name="reentrega"
                type="monotone"
                dataKey="valorReentrega"
                stroke="#FFBB28"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorReentrega)"
              />
              <Area
                name="retencao"
                type="monotone"
                dataKey="valorRetencao"
                stroke="#f5365c"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorRetencao)"
              />
              <Area
                name="sinistro"
                type="monotone"
                dataKey="valorSinistro"
                stroke="#e14eca"
                strokeWidth={2}
                fillOpacity={1}
                activeDot={{ r: 8 }}
                dot
                fill="url(#valorSinistro)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </CardBody>
    </Card>
  );
}
