import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Button } from "reactstrap";

const StatusButton = ({ status }) => {
  const inputRef = useRef(null);

  let color;
  let title;

  switch (status) {
    case "Pendente":
      color = "primary";
      title = "Pendente";
      break;
    case "Simulado":
      color = "info";
      title = "Simulado";
      break;
    case "Selecionado":
      color = "secondary";
      title = "Selecionado";
      break;
    default:
      color = "secondary";
      title = "Desconhecido";
  }

  return (
    <Button color={color} size="sm" innerRef={inputRef}>
      {title}
    </Button>
  );
};

StatusButton.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusButton;
