import React, { useRef, useEffect } from "react";
import NotificationAlert from "react-notification-alert";

const Toast = ({ message }) => {
  const alertRef = useRef();

  useEffect(() => {
    if (message) {
      const options = {
        place: message.place,
        message: (
          <div>
            <strong>{message.title}</strong>
            <p>{message.description}</p>
          </div>
        ),
        type: message.type,
        icon: message.icon,
        autoDismiss: message.type === "danger" ? 10000 : 5,
      };
      alertRef.current.notificationAlert(options);
    }
  }, [message]);

  return (
    <div className="rna-container">
      <NotificationAlert ref={alertRef} />
    </div>
  );
};

export default Toast;
