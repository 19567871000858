import Styled from 'styled-components';

export const Container = Styled.div``;

export const ContainerTable = Styled.div``;

export const ContainerButtonsModal = Styled.div`
    display: flex;
    flex-direction: row;
    align-itens: center;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
`;