import React, { useRef, useCallback, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.js";

import routes from "../../routes/public";

const Public = (props) => {
  const fullPagesRef = useRef("FullPages");

  const getRoutes = useCallback((routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }, []);
  const getActiveRoute = useCallback((routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  }, []);

  const getFullPageName = useCallback(
    (routes) => {
      let pageName = getActiveRoute(routes);
      switch (pageName) {
        case "Login":
          return "login-page";
        case "Register":
          return "register-page";
        default:
          return "Default Brand Text";
      }
    },
    [getActiveRoute]
  );

  useEffect(() => {
    document.body.classList.toggle("white-content");
  });

  return (
    <>
      <AuthNavbar
        brandText={
          getActiveRoute(routes) + " Page" === "Tracking Page"
            ? ""
            : getActiveRoute(routes) + " Page"
        }
      />
      <div className="wrapper wrapper-full-page" ref={fullPagesRef}>
        <div className={"full-page " + getFullPageName(routes)}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/public/tracking" />
          </Switch>
          {/* <Footer fluid /> */}
        </div>
      </div>
    </>
  );
};

export default Public;
