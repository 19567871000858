import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import api from "../../../services/api";
import CustomTable from "../../../components/CustomTable";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { useCompanies } from "../../../hooks/companies";

const Fatura = () => {
  const { empresa, transportadora } = useCompanies();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadFaturas() {
      setLoading(true);
      const response = await api.get("/faturas", {
        params: {
          embarcadorId: empresa.value,
          transportadoraId: transportadora.value,
        },
      });

      if (response.status === 200) {
        const faturas = response.data.map((fat) => ({
          ...fat,
          formattedValor: formatCurrency(fat.valor),
          formattedDataEmissao: formatDate(fat.data_emissao),
          formattedDataVencimento: formatDate(fat.data_vencimento),
        }));
        setData(faturas);
      }
      setLoading(false);
    }
    loadFaturas();
  }, [empresa, transportadora]);

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "nome_fantasia",
      text: "Transportadora",
    },
    {
      dataField: "numero",
      text: "Numero",
    },
    {
      dataField: "formattedValor",
      text: "Valor",
    },
    {
      dataField: "formattedDataEmissao",
      text: "Dt de Emissao",
    },
    {
      dataField: "formattedDataVencimento",
      text: "Dt de Vencimento",
    },
  ];

  return (
    <div className="content">
      <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
        <CardBody>
          <CustomTable
            data={data}
            columns={columns}
            fileName="faturas"
            tableName="Faturas"
            loading={loading}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Fatura;
