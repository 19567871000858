import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import ReactTable from "react-table";
import { ClipLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";

import api from "../../../services/api";

import { useCompanies } from "hooks/companies";

import { formatDateTime } from "../../../utils/formatDate";

const urlGet = "/integracoes/logs";

const TooltopRenderer = ({ mensagem, id }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const copyToken = () => {
    navigator.clipboard.writeText(mensagem);
  };
  return (
    <span>
      <p style={{ cursor: "pointer" }} id={`tooltip-${id}`} onClick={copyToken}>
        {mensagem}
      </p>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={`tooltip-${id}`}
        toggle={toggle}
      >
        {mensagem}
        <br />
        <br />
        Clique na mensagem para copiar
      </Tooltip>
    </span>
  );
};

const EcommerceIntegrationLog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(7);
  const [search, setSearch] = useState("");
  const alertRef = useRef();

  const { empresa } = useCompanies();

  async function loadData() {
    try {
      setLoading(true);
      const result = await api.get(urlGet, {
        params: search
          ? {
              embarcadorId: empresa.value,
              days: days,
              search: search,
            }
          : {
              embarcadorId: empresa.value,
              days: days,
            },
      });
      if (result.status === 200) {
        const dataCustom = result.data.map((item) => ({
          ...item,
          creationTime: item.creationTime
            ? formatDateTime(item.creationTime)
            : null,
        }));
        setData(dataCustom);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleDays = (daysInput) => {
    if (parseInt(daysInput) > 30) {
      return setDays(30);
    }

    if (parseInt(daysInput) < 1 || !daysInput) {
      return setDays(1);
    }

    return setDays(daysInput);
  };

  useEffect(() => {
    setData([]);
    loadData();
  }, [empresa, loadData]);

  const colunms = [
    {
      Header: "Embarcador Nota Fiscal",
      accessor: "embarcadorNotaFiscal",
      headerClassName: "text-center",
    },
    {
      Header: "Mensagem",
      headerClassName: "text-center",
      Cell: (row) => (
        <TooltopRenderer
          mensagem={row.original.mensagem}
          id={row.original.id}
        />
      ),
    },
    {
      Header: "Data de criação",
      accessor: "creationTime",
      headerClassName: "text-center",
    },
  ];

  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
      <Row>
        <Col className="ml-auto mr-auto">
          <h2 className="text-center">Pesquisa de Frete SPOT</h2>
        </Col>
      </Row>
      <Row>
        <div
          className="card"
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "collumn",
            justifyContent: "center",
          }}
        >
          <h3 className="text-center">Filtros</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingBottom: "10px",
            }}
          >
            <div>
              <label for="search">Filtro por palavra chave:</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  autoComplete="off"
                  value={search}
                  class="form-control"
                  type="text"
                  id="search"
                  placeholder="Pesquise por mensagem"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  class="btn btn-sm"
                  style={{ marginLeft: "5px", width: "160px" }}
                  onClick={loadData}
                >
                  Buscar
                </button>
              </div>
            </div>
            <div>
              <label for="cnpj">Filtro de dias:</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  autoComplete="off"
                  value={days}
                  class="form-control"
                  type="number"
                  id="search"
                  placeholder="Dias atrás"
                  onChange={(e) => handleDays(e.target.value)}
                  max="30"
                  min="1"
                />
                <button
                  class="btn btn-sm"
                  style={{ marginLeft: "5px", width: "160px" }}
                  onClick={loadData}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Card>
          <CardBody className="text-center">
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <ClipLoader
                  sizeUnit="px"
                  size={90}
                  color="#1d8cf8"
                  loading={loading}
                />
                <br />
                <br />
                <h1 className="text-info" style={{ textAlign: "center" }}>
                  Carregando ...
                </h1>
              </div>
            ) : (
              <ReactTable
                data={data}
                resizable={true}
                columns={colunms}
                defaultPageSize={5}
                showPaginationBottom
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado a ser exibido"
                pageText="Página"
                ofText="do"
                rowsText="linhas"
                rowsSelectorText="linhas por página"
                className="-striped -highlight"
              />
            )}
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default EcommerceIntegrationLog;
