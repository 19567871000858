import React, { useState, createContext, useContext } from 'react';
import moment from 'moment';

const DateFilterContext = createContext();

export function DateFilterProvider({ children }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const value = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };

  return (
    <DateFilterContext.Provider value={value}>
      {children}
    </DateFilterContext.Provider>
  );
}

export function useDateFilter() {
  const context = useContext(DateFilterContext);
  if (!context) {
    throw new Error('useDateFilter must be used within a DateFilterProvider');
  }
  return context;
}