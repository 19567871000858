import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import AuthLayout from "../layouts/Auth/Auth";
import AdminLayout from "../layouts/Admin/Admin";
import Public from "../layouts/Public";

const Routes = () => {
  return (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/public" component={Public} />
      <Route path="/app" component={AdminLayout} isPrivate />
      <Route path="/" exact component={AuthLayout} />
    </Switch>
  );
};

export default Routes;
