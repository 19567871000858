import moment from "moment";

export const formatDate = (date) => {
  if (date === null || date === undefined) return "-";

  const dateFormatted = moment(date).utc(0).format("DD/MM/YYYY");

  return dateFormatted;
};

export const formatDateTime = (date) => {
  if (date === null || date === undefined) return "-";

  const dateFormatted = moment(date).utc(0).format("DD/MM/YYYY HH:mm:ss");

  return dateFormatted;
};

export const formatTime = (date) => {
  if (date === null || date === undefined) return "-";

  const dateFormatted = moment(date).utc(0).format("HH:mm:ss");

  return dateFormatted;
};
