import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody, Row, Col } from "reactstrap";
import api from "../../../../../services/api"; // Importe o serviço de API
import "../../../../../assets/css/bigcalendar.css"; // Importe o CSS do react-big-calendar

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const [events, setEvents] = useState([]); // Altere o nome da variável de 'data' para 'events'
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(
    moment().add(90, "days").endOf("month")
  );

  useEffect(() => {
    async function loadEvents() {
      try {
        setLoading(true);
        const response = await api.get("/dashboard/auditoria/calendario", {
          params: {
            dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
            dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
          },
        });

        // console.log(response.data);

        if (response.status === 200) {
          setEvents(response.data); // Altere o estado 'data' para 'events'
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadEvents();
  }, [auxDtFinal, auxDtInicial]);

  const selectedEvent = (event) => {
    window.alert(event.title);
  };
  const addNewEventAlert = (slotInfo) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Input something"
        onConfirm={(e) => addNewEvent(e, slotInfo)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
      />
    );
  };
  const addNewEvent = (e, slotInfo) => {
    var newEvents = events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setAlert(null);
    setEvents(newEvents);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    // console.log(backgroundColor);
    return {
      className: backgroundColor,
    };
  };
  // Restante do código do componente...

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-calendar">
              <CardBody>
                <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                  Faturas por Vencimento
                </h3>{" "}
                {/* Cabeçalho centralizado */}
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <ClipLoader
                      sizeUnit="px"
                      size={90}
                      color="#1d8cf8"
                      loading={loading}
                    />
                    <br />
                    <br />
                    <h1 className="text-info" style={{ textAlign: "center" }}>
                      Carregando ...
                    </h1>
                  </div>
                ) : (
                  <BigCalendar
                    selectable
                    localizer={localizer}
                    events={events} // Utilize o estado 'events' no componente BigCalendar
                    defaultView="month"
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={(event) => selectedEvent(event)}
                    onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                    eventPropGetter={eventColors}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Calendar;
