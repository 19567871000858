import React, { useState } from "react";
import {
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
} from "reactstrap";
import { ContainerButtonsModal } from "./styles";

import ApiHubBackend from "services/ApiHubBackend";

const Credenciais = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [senha, setSenha] = useState("");

  const [token, setToken] = useState("");

  const [newAlert, setNewAlert] = useState({
    text: "",
    open: false,
    type: "info",
  });

  const openAlert = (text = "", type = "info") => {
    setNewAlert({
      text: text,
      open: !newAlert.open,
      type: type,
    });
  };

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const toggleDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  const copyToken = () => {
    navigator.clipboard.writeText(token);
    openAlert("Token copiado com sucesso", "success");
  };

  const createNewCredencial = () => {
    if (!email) {
      return openAlert("O email é obrigatório", "danger");
    } else if (!cnpj) {
      return openAlert("O CNPJ é obrigatório", "danger");
    } else if (!senha) {
      return openAlert("A senha é obrigatória", "danger");
    } else {
      ApiHubBackend.post("/credenciar", {
        cnpj: cnpj,
        email: email,
        password: senha,
      })
        .then(({ data }) => {
          navigator.clipboard.writeText(data.data.credenciais);
          setToken(data.data.credenciais);
          openAlert(`Credencial criada e token copiado!`, "success");
          setCnpj("");
          setEmail("");
          setSenha("");
          toggle();
        })
        .catch((err) => {
          navigator.clipboard.writeText(err);
          openAlert(
            "Erro ao criar nova credencial para esse usuário, verifique se já existe uma credencial ou se os dados estão válidos.",
            "danger"
          );
          setCnpj("");
          setEmail("");
          setSenha("");
          toggle();
        });
    }
  };

  // const colunms = [
  //   {
  //     Header: "Email",
  //     accessor: "email",
  //     headerClassName: "text-center",
  //   },
  //   {
  //     Header: "CNPJ",
  //     accessor: "cnpj",
  //     headerClassName: "text-center",
  //   },
  //   {
  //     Header: "Credencial",
  //     accessor: "token",
  //     headerClassName: "text-center",
  //   },
  //   {
  //     Header: "Ações",
  //     accessor: "actions",
  //     Cell: (row) => (
  //       <div>
  //         <button
  //           className="btn-icon btn-round btn-alert"
  //           style={{ margin: "3px" }}
  //           onClick={() => {
  //             copyToken(row.original.token);
  //           }}
  //         >
  //           <i className="tim-icons icon-single-copy-04" />
  //         </button>
  //         <button
  //           className="btn-icon btn-round btn-danger"
  //           style={{ margin: "3px" }}
  //           onClick={() => {
  //             setEmail(row.original.email);
  //             toggleDelete();
  //           }}
  //         >
  //           <i className="tim-icons icon-trash-simple" />
  //         </button>
  //       </div>
  //     ),
  //     headerClassName: "text-center",
  //     sortable: false,
  //     filterable: false,
  //   },
  // ];

  return (
    <div className="content">
      <Alert
        color={newAlert.type}
        isOpen={newAlert.open}
        toggle={() => openAlert("", "")}
      >
        {newAlert.text}
      </Alert>
      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2 class="text-dark">Nova credencial</h2>
        </ModalHeader>
        <ModalBody>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              autoComplete="off"
              value={email}
              class="form-control text-dark"
              type="email"
              id="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input
              value={cnpj}
              class="form-control text-dark"
              id="cnpj"
              placeholder="0.000.000/0000-00"
              onChange={(e) => setCnpj(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label for="cnpj">Senha</label>
            <input
              autoComplete="off"
              value={senha}
              class="form-control text-dark"
              type="password"
              id="senha"
              placeholder="*********"
              onChange={(e) => setSenha(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={createNewCredencial}
            >
              Criar
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openModalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>
          <h2 class="text-dark">{email}</h2>
        </ModalHeader>
        <ModalBody>
          <h2 class="text-dark">
            Você realmente quer excluir as credenciais do usuário {email}?
          </h2>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={() => {
                setEmail("");
                toggleDelete();
              }}
            >
              Não
            </button>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={() => {}}
            >
              Sim
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Row>
        <h1>Credenciais</h1>
      </Row>

      <Row>
        <button
          type="button"
          class="btn btn-primary btn-round"
          onClick={toggle}
        >
          Novo
        </button>
      </Row>

      <Row>
        {/* <Card>
            <CardBody className="text-center">
              <ReactTable
                data={[{ email: 'teste@teste.com', cnpj: 'teste', token: 'teste' }]}
                resizable={true}
                columns={colunms}
                defaultPageSize={5}
                showPaginationBottom
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado a ser exibido"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                rowsSelectorText="linhas por página"
                className="-striped -highlight"
              />
            </CardBody>
          </Card> */}

        {token ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <h2>Token gerado, clique nele para copiar.</h2>
            <h3 onClick={copyToken}>{token}</h3>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <h2>Nenhum token gerado.</h2>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Credenciais;
