import React from "react";
import classnames from "classnames";

// reactstrap components
import { NavbarBrand, Navbar, Container } from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {this.props.brandText}
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
