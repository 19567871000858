import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
} from "reactstrap";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatCurrency";
import { ClipLoader } from "react-spinners";

import api from "../../../services/api";
import { useCompanies } from "hooks/companies";

import ModalFilters from "../../../components/CustomModalFilters";
import ListCtes from "./components/ListCtes";
import TableCtes from "./components/TableCtes";

// import { Container } from './styles';

export default function Extrato() {
  const { empresa, transportadora } = useCompanies();

  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(moment().startOf("month"));
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(moment().startOf("month"));
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));
  const [badgeDataInicial, setBadgeDataInicial] = useState(
    moment().startOf("month").format("DD/MM/YYYY")
  );
  const [badgeDataFinal, setBadgeDataFinal] = useState(
    moment().endOf("month").format("DD/MM/YYYY")
  );

  const [listCtes, setListCtes] = useState([]);
  const [tableCtes, setTableCtes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTableCtes, setDataTableCtes] = useState("");

  useEffect(() => {
    async function loadExtrato() {
      setLoading(true);
      const responseList = await api.get(
        "transportadora/extrato/listar-extrato",
        {
          params: {
            empresaId: empresa.value,
            transportadoraId: transportadora.value,
            dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
            dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
          },
        }
      );

      if (responseList.status === 200) {
        setListCtes(responseList.data);
      }
      setTableCtes([]);
      setLoading(false);
    }
    loadExtrato();
  }, [empresa, transportadora, auxDtInicial, auxDtFinal]);

  const handleDateInicial = useCallback((e) => {
    setDataInicial(e);
  }, []);

  const handleDateFinal = useCallback((e) => {
    setDataFinal(e);
  }, []);

  const toggleModalFilters = useCallback(() => {
    setModalFilters(!modalFilters);
  }, [modalFilters]);

  const onClearFilters = useCallback(() => {
    setDataInicial(moment().startOf("month"));
    setDataFinal(moment().endOf("month"));
    setAuxDtInicial(moment().endOf("month"));
    setDataFinal(moment().endOf("month"));
    setBadgeDataInicial(moment().startOf("month").format("DD/MM/YYYY"));
    setBadgeDataFinal(moment().endOf("month").format("DD/MM/YYYY"));
  }, []);

  const loadTableCtes = useCallback(
    (date) => {
      async function loadTable() {
        const responseTableCtes = await api.get(
          "transportadora/extrato/listar-ctes",
          {
            params: {
              empresaId: empresa.value,
              transportadoraId: transportadora.value,
              dataInicial: moment(date).format("YYYY-MM-DD 00:00:00"),
              dataFinal: moment(date).format("YYYY-MM-DD 23:59:59"),
            },
          }
        );

        if (responseTableCtes.status === 200) {
          const table = responseTableCtes.data.map((item) => {
            return {
              numeroCte: item.cte_numero,
              totalFrete: formatCurrency(item.cte_valor_frete),
              numeroFatura:
                item.fat_situacao === 3
                  ? item.fat_numero || "Sem Número"
                  : "Não Faturado",
              dataEmissao: moment(item.cte_data_emissao).format("DD/MM/YYYY"),
              notasFiscais: item.nf_numero,
            };
          });

          setTableCtes(table);
          setDataTableCtes(moment(date).format("DD - MMM"));
        }
      }
      loadTable();
    },
    [empresa.value, transportadora.value]
  );

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setBadgeDataInicial(dataInicial.format("DD/MM/YYYY"));
    setBadgeDataFinal(dataFinal.format("DD/MM/YYYY"));
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <div className="content">
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <Row>
        <Col lg="12" md="12" sm="12">
          <Card className="card-plain">
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">
                    <i className="tim-icons icon-paper" /> Extrato
                  </CardTitle>
                </Col>
                <Col className="text-right">
                  <Button
                    className="btn-round btn-sm"
                    onClick={toggleModalFilters}
                    color="success"
                  >
                    Filtrar
                  </Button>
                  <Button
                    className="btn-round btn-sm"
                    color="danger"
                    onClick={onClearFilters}
                  >
                    Limpar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {badgeDataInicial || badgeDataFinal ? (
                    <>
                      <Badge color="info">
                        data inicial: {badgeDataInicial}
                      </Badge>
                      <Badge color="info">data final: {badgeDataFinal}</Badge>
                    </>
                  ) : null}
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader
            sizeUnit="px"
            size={90}
            color="#1d8cf8"
            loading={loading}
          />
          <br />
          <br />
          <h1 className="text-info" style={{ textAlign: "center" }}>
            Carregando ...
          </h1>
        </div>
      ) : (
        <Row>
          <Col lg="5" md="5" sm="5">
            <Card className="card-timeline card-plain">
              <CardBody>
                <ListCtes
                  listCtes={listCtes}
                  tableCtes={(date) => loadTableCtes(date)}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="7" md="7" sm="7">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col>
                    <Badge style={{ backgroundColor: "#ffd600" }}>
                      Lista de ctes do dia: {dataTableCtes}
                    </Badge>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCtes data={tableCtes} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
