import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { useField } from "@unform/core";

const CustomInput = ({ name, placeholder, type, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [inputState, setInputState] = useState("");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (error) {
      setInputState("has-danger");
    } else {
      setInputState("");
    }
  }, [error]);

  return (
    <>
      <Input
        name={name}
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        innerRef={inputRef}
        {...rest}
      />
      {inputState === "has-danger" ? (
        <label style={{ color: "#ec250d" }}>{error}</label>
      ) : null}
    </>
  );
};

export default CustomInput;
