import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import moment from "moment";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";

import api from "../../../../../services/api";
import ChartAreaOneLine from "../../../../../components/ChartAreaOneLine";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { useTheme } from "../../../../../hooks/theme";
import { useCompanies } from "../../../../../hooks/companies"; // Importar useCompanies para capturar as seleções
import ModalFilters from "../../../../../components/CustomModalFilters";

export default function ChartFreteClienteUF() {
  const { darkMode } = useTheme();
  const { empresa, transportadora } = useCompanies(); // Obtém as seleções de empresa e transportadora

  const [bigChartData, setBigChartData] = useState("totalNf");
  const [dataNf, setDataNf] = useState([]);
  const [dataCliente, setDataCliente] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);

        const params = {
          dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
          dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
        };

        if (empresa) {
          params.embarcadores = [empresa.value]; // Adiciona o ID da empresa como array
        }

        if (transportadora) {
          params.transportadoras = [transportadora.value]; // Adiciona o ID da transportadora como array
        }

        const responseNf = await api.get(
          "/dashboard/custo/frete-por-uf-por-notafiscal",
          { params }
        );

        const responseCliente = await api.get(
          "/dashboard/custo/frete-por-uf-por-cliente",
          { params }
        );

        if (responseNf.status === 200) {
          setDataNf(responseNf.data);
        }

        if (responseCliente.status === 200) {
          setDataCliente(responseCliente.data);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    loadCharts();
  }, [auxDtFinal, auxDtInicial, empresa, transportadora]); // Adiciona empresa e transportadora como dependências

  // const CustomTooltip = (props) => {
  //   const { active } = props;

  //   if (active) {
  //     const { payload } = props;
  //     const payFormatted = payload.map((pay) => ({
  //       ref: "UF",
  //       sigla: pay.payload.sigla,
  //       name: "Valor:",
  //       value: formatCurrency(pay.payload.valor),
  //     }));
  //     return (
  //       <div
  //         className="tooltip-inner"
  //         style={{
  //           maxWidth: "500px",
  //           textAlign: "left",
  //         }}
  //       >
  //         <p
  //           style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
  //         >
  //           <strong>{`${payFormatted[0].ref}: `}</strong>
  //           {payFormatted[0].sigla}
  //         </p>
  //         <p
  //           style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
  //         >
  //           <strong>{`${payFormatted[0].name}: `}</strong>
  //           {payFormatted[0].value}
  //         </p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row>
          <Col>
            {bigChartData === "totalNf" ? (
              <>
                <h4>Resultado do Frete Cobrado do Cliente</h4>
                <CardTitle>
                  Percentual do frete do cliente sobre o frete da transportadora
                </CardTitle>
              </>
            ) : (
              <>
                <h4>Resultado do Frete Cobrado do Cliente</h4>
                <CardTitle>
                  Resultado do frete do cliente sobre o frete da transportadora
                </CardTitle>
              </>
            )}
          </Col>
          <Col sm="6">
            <Button
              className="btn-sm"
              color="info"
              onClick={toggleModalFilters}
            >
              Período
            </Button>
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                color="info"
                id="0"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalNf",
                })}
                onClick={() => setBigChartData("totalNf")}
              >
                <input defaultChecked name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Percentual
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-coins" />
                </span>
              </Button>
              <Button
                color="info"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalWheight",
                })}
                onClick={() => setBigChartData("totalWheight")}
              >
                <input name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Valor
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-delivery-fast" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {bigChartData === "totalNf" ? (
          <ChartAreaOneLine
            data={dataNf}
            grid="rgba(29, 140, 248, 0.1)"
            lineColor="#1f8ef1"
            dataKey="valor"
            name="Percentual"
            measure="%"
            yAxisWidth={100}
            loading={loading}
            idLinha="totalNf"
          />
        ) : (
          <ChartAreaOneLine
            data={dataCliente}
            grid="rgba(45,206,137, 0.1)"
            lineColor="#10e211"
            dataKey="valor"
            name="Valor"
            measure="R$"
            yAxisWidth={100}
            loading={loading}
            idLinha="totalCliente"
          />
        )}
      </CardBody>
    </Card>
  );
}
