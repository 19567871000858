import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import api from "../../../../../services/api";
import { unitFormat } from "utils/unitFormat";
import { useTheme } from "../../../../../hooks/theme";
import ModalFilters from "../../../../../components/CustomModalFilters";

export default function ChartEntregaPorTransp() {
  const { darkMode } = useTheme();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const color = darkMode ? "#e9ecef" : "#1e1e2f";
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const response = await api.get(
          "/dashboard/performance/entrega-por-transportador",
          {
            params: {
              dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
              dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
            },
          }
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, [auxDtFinal, auxDtInicial]);

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;
      const payFormatted = payload.map((pay) => ({
        ref: "Frete",
        descricao: pay.payload.custo_frete,
        name: pay.name,
        value: unitFormat(pay.value),
      }));
      return (
        <div
          className="tooltip-inner"
          style={{
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].ref}: `}</strong>
            {payFormatted[0].descricao}
          </p>
          <p
            style={{ color: darkMode ? "#1e1e2f" : "#e9ecef", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].name}: `}</strong>
            {payFormatted[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <Card
      style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
      className="card-chart"
    >
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <h4>Entregas por Transportadora</h4>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              className="btn-sm"
              color="info"
              onClick={toggleModalFilters}
            >
              Período
            </Button>
          </Col>
        </Row>
        <CardTitle>
          Entregas por Transportadora e por período (3 meses)
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <Row className="justify-content-center">
            <Col>
              <ResponsiveContainer width="100%" height={250}>
                {data.length > 0 ? (
                  <BarChart
                    data={data}
                    margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
                  >
                    <defs>
                      <linearGradient id="bar1" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="20%"
                          stopColor="#2dce89"
                          stopOpacity={0.3}
                        />
                        <stop
                          offset="90%"
                          stopColor="#2dce89"
                          stopOpacity={0.1}
                        />
                        <stop
                          offset="90%"
                          stopColor="#2dce89"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid
                      stroke="rgba(45,206,137, 0.1)"
                      strokeWidth={1}
                    />
                    <XAxis
                      dataKey="descricao"
                      stroke={color}
                      style={{ fontWeight: 100 }}
                    />
                    <YAxis
                      tickFormatter={(tick) => unitFormat(tick)}
                      stroke={color}
                      style={{ fontWeight: 100 }}
                      width={100}
                    />
                    <Tooltip content={(props) => CustomTooltip(props)} />
                    <Legend />
                    <Bar
                      name="Entregas"
                      dataKey="qtd_entregas"
                      type="monotone"
                      stroke="#10e211"
                      fillOpacity={1}
                      fill="url(#bar1)"
                    >
                      <LabelList dataKey="qtd" position="top" />
                    </Bar>
                  </BarChart>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <h5>Nenhum dado para apresentar!</h5>
                  </div>
                )}
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
