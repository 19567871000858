import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import api from "../../../services/api";
import CustomTable from "../../../components/CustomTable";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { useCompanies } from "../../../hooks/companies";

const NotaFiscal = () => {
  const { empresa, transportadora } = useCompanies();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadNotasFiscais() {
      setLoading(true);
      const response = await api.get("/notas-fiscais", {
        params: {
          embarcadorId: empresa.value,
          transportadoraId: transportadora.value,
        },
      });

      if (response.status === 200) {
        const notasFiscais = response.data.map((nf) => ({
          ...nf,
          formattedValor: formatCurrency(nf.valor),
          formattedDataEmissao: formatDate(nf.data_emissao),
        }));
        setData(notasFiscais);
      }
      setLoading(false);
    }
    loadNotasFiscais();
  }, [empresa, transportadora]);

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "nome_fantasia",
      text: "Transportadora",
    },
    {
      dataField: "numero",
      text: "Numero",
    },
    {
      dataField: "serie",
      text: "Serie",
    },
    {
      dataField: "formattedValor",
      text: "Valor",
    },
    {
      dataField: "formattedDataEmissao",
      text: "Dt de Emissao",
    },
  ];

  return (
    <div className="content">
      <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
        <CardBody>
          <CustomTable
            data={data}
            columns={columns}
            fileName="notas_fiscais"
            tableName="Notas Fiscais"
            loading={loading}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default NotaFiscal;
