import axios from "axios";
import env from "../environment";

const apiPortalTransportador = axios.create({
  baseURL: env.API_PDT,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("@HubEverlog:token"),
  },
});

apiPortalTransportador.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("@HubEverlog:token");
          localStorage.removeItem("@HubEverlog:user");
          // window.location.href = "/";
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default apiPortalTransportador;
