import React, { useState, useCallback, useRef, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import queryString from "query-string";
import NotificationAlert from "react-notification-alert";

import CardResult from "./components/CardResult";
import api from "../../../services/api";
import { formatDate } from "../../../utils/formatDate";
import { useToast } from "../../../hooks/toast";

const Tracking = ({ location }) => {
  const [urlParams] = useState(location.search);
  const [dataNf, setDataNf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [logoLink, setLogoLink] = useState(false);
  const paramsParsed = queryString.parse(urlParams);

  const { chaveNf } = paramsParsed;

  const alertRef = useRef();

  const { addToast } = useToast();

  const setError = useCallback(
    (error) => {
      if (error) {
        const message = {
          place: "tr",
          type: "danger",
          title: "Error",
          description: "Erro de conexão com o servidor!",
          icon: "tim-icons icon-alert-circle-exc",
        };
        addToast(message);
        setLoading(false);
      } else {
        setDataNf(null);
        const message = {
          place: "tr",
          type: "danger",
          title: "Error",
          description: "Nota Fiscal não encontrada!",
          icon: "tim-icons icon-alert-circle-exc",
        };
        addToast(message);
        setLoading(false);
      }
    },
    [addToast]
  );

  const getImageUrl = (id) => {
    id !== null && api.get(`/integracoes/download-logo-rastreio/${id}`).then((result) => {
      setLogoLink(result.data)
    }).catch(() => {
      console.log('Erro ao buscar logo')
    })

    id === null && console.log('id de integração nulo')
  };

  useEffect(() => {
    async function loadNotaFiscal() {
      if (chaveNf && chaveNf.length === 44) {
        setLoading(true);
        try {
          const result = await api.get("/tracking", {
            params: {
              chaveNf,
            },
          });

          if (result.status === 200 && result.data.length > 0) {
            const notaFiscal = result.data.map((nf) => ({
              dt_emissao: nf.data_emissao ? formatDate(nf.data_emissao) : null,
              dt_previsao_entrega: nf.previsao_entrega
                ? formatDate(nf.previsao_entrega)
                : null,
              dt_entrega: nf.entrega ? formatDate(nf.entrega) : null,
              ...nf,
            }));
            getImageUrl(result.data[0].id_integracao_ecommerce);
            setDataNf(notaFiscal);
            setLoading(false);
          } else {
            setError();
          }
        } catch (error) {
          console.log(error);
          setError(error);
        }
      }
    }
    loadNotaFiscal();
  }, [chaveNf, setError, urlParams]);

  return (
    <div className="content">
      <Col style={{ marginTop: -100 }}>
        <div className="rna-container">
          <NotificationAlert ref={alertRef} />
        </div>
        <Row>
          {
            logoLink && (
              <Col xs="2">
                <img src={logoLink} style={{
                  width: 150,
                  marginBottom: 10
                }} />
              </Col>
            )
          }
          <Col xs={logoLink ? '8' : '12'} style={{ top: logoLink ? 30 : 0 }}>
            <h3 className="title text-center">
              Visualize abaixo como está a sua entrega
            </h3>
          </Col>
        </Row>
        <br />
        {loading ? (
          <Row>
            <Col className="ml-auto mr-auto text-center" md="1">
              <Spinner
                style={{ marginBottom: 10, width: "3rem", height: "3rem" }}
                color="info"
              />
              <h6>Carregando...</h6>
            </Col>
          </Row>
        ) : (
          <Col className="ml-auto mr-auto text-center" md="10">
            {dataNf ? (
              <CardResult data={dataNf} />
            ) : (
              <h6>Nota Fiscal não encontrada!</h6>
            )}
          </Col>
        )}
      </Col>
    </div>
  );
};

export default Tracking;
