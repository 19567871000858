import currency from "currency.js";

export const formatCurrency = (value) => {
  const valueFormatted = currency(value, {
    symbol: "R$",
    separator: ".",
    decimal: ",",
    formatWithSymbol: true,
    precision: 0,
  }).format();

  return valueFormatted;
};
