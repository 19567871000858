import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { FaFileUpload } from "react-icons/fa";

import apiPortalTransportador from "../../../services/apiPortalTransportador";
import { useToast } from "../../../hooks/toast";
import FileUpload from "../../../components/Input/fileUpload";

import { useCompanies } from "hooks/companies";

const urlGetTypes = "/UploadArquivo/TipoInformacao";
const urlPostImportCsv = "/UploadArquivo/ArquivoTransportador";

const UploadFilesTransporter = () => {
  const [modal, setModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const alertRef = useRef();
  const { addToast } = useToast();

  const { empresa, transportadora } = useCompanies();

  const [selectType, setSelectType] = useState("");
  const [types, setTypes] = useState([]);

  const handleSelectType = async (type) => {
    setSelectType(type);
    setIsSelected(true);
  };

  useEffect(() => {
    const loadDataTypes = async () => {
      setSelectType("");
      setTypes([]);
      try {
        setLoading(true);

        const response = await apiPortalTransportador.get(
          `${urlGetTypes}/${empresa.value}/${transportadora.value}`
        );

        console.log(response.data);

        if (response.status === 200 && response.data.length > 0) {
          const responseFormated = response.data.map(function (item) {
            return {
              value: item.Id,
              label: item.Nome,
            };
          });
          setTypes(responseFormated);
          setIsSelected(false);
        }
        setLoading(false);
      } catch (error) {
        setIsSelected(false);
        setLoading(false);
        const message = {
          place: "tr",
          type: "danger",
          title: "Error",
          description:
            "Erro ao buscar tipos! : " +
            (error.response.data ? error.response.data : error),
          icon: "tim-icons icon-alert-circle-exc",
        };
        addToast(message);
      }
    };

    loadDataTypes();
  }, [empresa.value, transportadora.value, addToast]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <i className="tim-icons icon-simple-remove text-black" />
          </button>
          <ModalHeader tag="h3">Enviar arquivos</ModalHeader>
        </div>
        <ModalBody className="text-center">
          <Row>
            <Col className="text-center">
              <FileUpload
                api={apiPortalTransportador}
                url={urlPostImportCsv}
                params={null}
                paramsFormData={[
                  { name: "idEmbarcador", value: empresa.value },
                  { name: "idTransportador", value: transportadora.value },
                  { name: "idTipoInformacao", value: selectType.value },
                ]}
                accept=".zip"
                filekey="ArquivoZip"
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Row>
        <Col className="ml-auto mr-auto">
          <h2 className="text-center">Upload de arquivos transportador</h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="mr-auto" sm={6}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <ClipLoader
                sizeUnit="px"
                size={90}
                color="#1d8cf8"
                loading={loading}
              />
              <br />
              <br />
              <h1 className="text-info" style={{ textAlign: "center" }}>
                Carregando tipos...
              </h1>
            </div>
          ) : (
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="selectEmpresa"
              value={selectType}
              onChange={(value) => handleSelectType(value)}
              options={types}
              placeholder="Tipos"
            />
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="ml-auto mr-auto">
          <Button
            className="btn btn-round btn-success"
            onClick={() => setModal(!modal)}
            disabled={!isSelected}
          >
            <FaFileUpload size={25} />
            {"  "} Enviar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UploadFilesTransporter;
