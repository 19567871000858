import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

import api from "../../../../../services/api";
import ChartAreaOneLine from "../../../../../components/ChartAreaOneLine";
import { useCompanies } from "../../../../../hooks/companies"; // Importa o hook useCompanies

export default function ChartFretePorNf() {
  const { empresa, transportadora } = useCompanies(); // Obtém as seleções de empresa e transportadora

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);

        const params = {};

        if (empresa) {
          params.embarcadores = [empresa.value]; // Adiciona o ID da empresa como array
        }

        if (transportadora) {
          params.transportadoras = [transportadora.value]; // Adiciona o ID da transportadora como array
        }

        const response = await api.get(
          "/dashboard/custo/frete-por-notafiscal",
          { params }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    if (empresa) {
      loadCharts(); // Carrega os gráficos quando empresa ou transportadora mudar
    }
  }, [empresa, transportadora]); // Adiciona empresa e transportadora como dependências

  return (
    <Card
      style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
      className="card-chart"
    >
      <CardHeader>
        <h4>Frete sobre NF</h4>
        <CardTitle>Percentual de frete sobre o valor da NF</CardTitle>
      </CardHeader>
      <CardBody>
        <ChartAreaOneLine
          data={data}
          grid="rgba(245,54,92, 0.1)"
          lineColor="#f5365c"
          dataKey="valor"
          name="percentual do frete"
          measure="%"
          yAxisWidth={60}
          loading={loading}
          idLinha="freteNf"
        />
      </CardBody>
    </Card>
  );
}
