import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useDateFilter } from '../../hooks/datefilter';
import ModalFilters from '../CustomModalFilters';
import moment from 'moment';

const DateFilterButton = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useDateFilter();
  const [modalOpen, setModalOpen] = useState(false);

  // Estados locais para armazenar as datas selecionadas no modal
  const [localStartDate, setLocalStartDate] = useState(
    startDate || moment().subtract(365, 'days').startOf('month')
  );
  const [localEndDate, setLocalEndDate] = useState(
    endDate || moment().endOf('month')
  );

  const toggleModal = () => setModalOpen(!modalOpen);

  // Atualiza os estados locais quando o modal é aberto
  useEffect(() => {
    if (modalOpen) {
      setLocalStartDate(startDate || moment().subtract(90, 'days').startOf('month'));
      setLocalEndDate(endDate || moment().endOf('month'));
    }
  }, [modalOpen, startDate, endDate]);

  const handleSubmit = () => {
    // Atualiza o contexto com as datas selecionadas quando o usuário clica em "Filtrar"
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    toggleModal();
  };

  return (
    <>
      <Button color="info" onClick={toggleModal}>
        Período
      </Button>
      <ModalFilters
        open={modalOpen}
        toggle={toggleModal}
        dataInicial={localStartDate}
        dataFinal={localEndDate}
        handleDateInicial={setLocalStartDate} // Atualiza o estado local
        handleDateFinal={setLocalEndDate}     // Atualiza o estado local
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default DateFilterButton;