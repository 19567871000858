import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import AppProvider from "./hooks";

import Routes from "./routes";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <AppProvider>
      <Routes />
    </AppProvider>
  </Router>,
  document.getElementById("root")
);
