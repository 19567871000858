import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Cell,
} from "recharts";

import api from "../../../../../services/api";
import { unitFormat } from "utils/unitFormat";
import { useTheme } from "../../../../../hooks/theme";
import ModalFilters from "../../../../../components/CustomModalFilters";

export default function ChartPrazoPorTransp() {
  const { darkMode } = useTheme();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const color = darkMode ? "#e9ecef" : "#32325d";
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const response = await api.get(
          "/dashboard/performance/prazo-por-transportador",
          {
            params: {
              dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
              dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
            },
          }
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, [auxDtFinal, auxDtInicial]);

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;
      const payFormatted = payload.map((pay) => ({
        ref: "Resultado",
        descricao: pay.payload.diferencaPrazo,
        name: pay.name,
        value: unitFormat(pay.value),
      }));
      return (
        <div
          className="tooltip-inner"
          style={{
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p
            style={{ color: darkMode ? "#212529" : "#ffffff", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].ref}: `}</strong>
            {payFormatted[0].descricao}
          </p>
          <p
            style={{ color: darkMode ? "#212529" : "#ffffff", fontWeight: 100 }}
          >
            <strong>{`${payFormatted[0].name}: `}</strong>
            {payFormatted[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  const roundToNearestMultipleOfThree = (num) => {
    return Math.ceil(num / 4) * 4;
  };

  const calculateTicks = (maxValue) => {
    const expandedMax = roundToNearestMultipleOfThree(maxValue * 1.2);
    const interval = Math.ceil(expandedMax / 4); 
    const ticks = [];
    for (let i = 0; i <= expandedMax; i += interval) {
      ticks.push(i);
    }
    return ticks;
  };

  const maxPrazo = Math.max(
    ...data.map((item) => item.prazoMedioPrevisto),
    ...data.map((item) => item.prazoMedioReal)
  );

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }} className="card-chart">
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <h4>Prazo por Transportadora</h4>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              className="btn-sm"
              color="info"
              onClick={toggleModalFilters}
            >
              Período
            </Button>
          </Col>
        </Row>
        <CardTitle>Prazos por Transportadora e por período (3 meses)</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <Row className="justify-content-center">
            <Col>
              <ResponsiveContainer width="100%" minHeight={400}>
                <BarChart
                  data={data}
                  margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
                >
                  <defs>
                    <linearGradient id="bar-blue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#5e72e4" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#5e72e4" stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient id="bar-green" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2dce89" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#2dce89" stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient id="bar-red" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#f5365c" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#f5365c" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="rgba(45,206,137, 0.1)" strokeWidth={1} />
                  <XAxis
                    dataKey="transportadora"
                    stroke={color}
                    padding={{ left: 20, right: 20 }}
                    tick={{ dy: 3 }}
                    style={{ fontWeight: 100 }}
                  />
                  <YAxis
                    tickFormatter={(tick) => unitFormat(tick)}
                    stroke={color}
                    style={{ fontWeight: 100 }}
                    width={100}
                    domain={[0, roundToNearestMultipleOfThree(maxPrazo * 1.2)]}
                    ticks={calculateTicks(maxPrazo)}
                  />
                  <Tooltip content={(props) => CustomTooltip(props)} />
                  <Legend iconType="rect" layout="horizontal" verticalAlign="top" align="center" />

                  {/* Coluna para Prazo Médio Previsto com gradiente azul */}
                  <Bar name="Prazo Previsto" dataKey="prazoMedioPrevisto" fill="url(#bar-blue)">
                    <LabelList
                      dataKey="prazoMedioPrevisto"
                      position="top"
                      offset={10}
                      fill={darkMode ? "#ffffff" : "#000000"}
                    />
                  </Bar>

                  {/* Coluna para Prazo Médio Real com cores condicionais */}
                  <Bar name="Prazo Real" dataKey="prazoMedioReal" fillOpacity={1}>
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.prazoMedioReal > entry.prazoMedioPrevisto
                            ? "url(#bar-red)" // Excedido
                            : "url(#bar-green)" // Dentro do prazo
                        }
                      />
                    ))}
                    <LabelList
                      dataKey="prazoMedioReal"
                      position="top"
                      offset={10}
                      fill={darkMode ? "#ffffff" : "#000000"}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}