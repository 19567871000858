import React from "react";
import { Row, Col, CardTitle } from "reactstrap";
import { ClipLoader } from "react-spinners";
import BootstrapTable from "react-bootstrap-table-next";

const CustomTable = ({ data, columns, tableName, loading }) => {
  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader size={90} color="#1d8cf8" loading={loading} />
        <br />
        <br />
        <h1 className="text-info">Carregando ...</h1>
      </div>
    );
  }

  if (!columns || columns.length === 0 || !data || data.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <h4 className="text-info">Nenhum dado disponível para exibir.</h4>
      </div>
    );
  }

  const columnsFormatted = columns.map((col) => {
    const columnConfig = {
      dataField: col.dataField,
      text: col.text,
      sort: false,
      // Outros estilos ou propriedades
    };

    // Alinhar a coluna '#' (campo 'name') à esquerda
    if (col.dataField === "name") {
      columnConfig.align = "left"; // Alinha as células de dados à esquerda
      columnConfig.headerAlign = "left"; // Alinha o cabeçalho à esquerda (opcional)
    } else {
      // Alinha as outras colunas ao centro (opcional)
      columnConfig.align = "center";
      columnConfig.headerAlign = "center";
    }

    return columnConfig;
  });

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col md={3}>
          <CardTitle tag="h4">
            <i className="tim-icons icon-paper" /> {tableName}
          </CardTitle>
        </Col>
      </Row>
      <hr />
      <BootstrapTable
        keyField="name"
        data={data}
        columns={columnsFormatted}
        noDataIndication="Consulta não retornou dados."
        bordered={false}
        striped
        classes="text-center"
      />
    </>
  );
};

export default CustomTable;
