import Dashboard from "../views/Admin/Dashboard";
import Users from "../views/Admin/Users";
import Transportadoras from "views/Admin/Transportadoras";
import UsuariosPDT from "views/Admin/UsuariosPDT";
import Companies from "views/Admin/Companies";


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/app",
    authorized: "Pages.HUB.Dashboard",
    setor: "admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/app",
    authorized: "Pages.HUB.Users",
  },
  {
    collapse: true,
    state: "relatoriosCollapse",
    name: "Portal do transportador",
    icon: "tim-icons icon-app",
    layout: "/app",
    authorized: null,
    views: [
      {
        path: "/pdt/transportadoras",
        name: "Transportadoras",
        mini: "TPA",
        component: Transportadoras,
        layout: "/app",
        authorized: "Pages.HUB.PortalTransportador.Transportadoras",
      },
      {
        path: "/pdt/usuarios",
        name: "Usuários",
        mini: "USR",
        component: UsuariosPDT,
        layout: "/app",
        authorized: "Pages.HUB.PortalTransportador.Usuarios",
      },
      {
        path: "/pdt/companies",
        name: "Companhias",
        mini: "CPN",
        component: Companies,
        layout: "/app",
        authorized: "Pages.HUB.PortalTransportador.Companias",
      },
    ],
  },
];

export default routes;
