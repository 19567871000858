import PropTypes from 'prop-types';
import React, { useRef } from "react";
import { Button } from "reactstrap";

const Status = ({ status, title }) => {
  const inputRef = useRef(null);

  if(status === "Inativo" ) {
    return (
      <Button
        color="danger"
        size="sm"
        innerRef={inputRef}
      >
        {title}
      </Button>
      );
  }

  return (
  <Button
    color="info"
    size="sm"
    innerRef={inputRef}
  >
    {title}
  </Button>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Status;


// status pode ser ativo ou inativo