import React, { createContext, useContext, useState, useCallback } from "react";

const CompaniesContext = createContext({});

export const CompaniesProvider = ({ children }) => {
  const [empresa, setEmpresa] = useState(null);
  const [optionsEmpresa, setOptionsEmpresa] = useState(null);
  const [transportadora, setTransportadora] = useState(null);
  const [optionsTransportadora, setOptionsTransportadora] = useState(null);

  const loadCompanies = useCallback((user) => {
    const { empresas } = user;

    // console.log(empresas);
    setEmpresa(empresas[0]);
    setOptionsEmpresa(empresas);
    setTransportadora(null);
    setOptionsTransportadora(empresas[0].transportadoras);
  }, []);

  const selectEmpresa = useCallback((empresa) => {
    setEmpresa(empresa);
    setTransportadora(null);
    setOptionsTransportadora(empresa.transportadoras);
  }, []);

  const selectTransportadora = useCallback((transportadora) => {
    setTransportadora(transportadora);
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        empresa,
        optionsEmpresa,
        transportadora,
        optionsTransportadora,
        loadCompanies,
        selectEmpresa,
        selectTransportadora,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export const useCompanies = () => {
  const context = useContext(CompaniesContext);

  if (!context) {
    throw new Error("useCompanies must be used within an AuthProvider");
  }
  return context;
};
