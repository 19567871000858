import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ReactTable from "react-table";
import { Form } from "@unform/web";
import * as Yup from "yup";
import NotificationAlert from "react-notification-alert";

import CustomInput from "../../../components/Input";
import api from "../../../services/api";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useToast } from "../../../hooks/toast";

const Users = () => {
  const [listUsers, setListUsers] = useState([]);
  const [modalCreatePassword, setModalCreatePassword] = useState(false);
  const [user, setUser] = useState(null);
  const formRef = useRef(null);
  const alertRef = useRef();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadUsers() {
      const users = await api.get("/users");
      if (users.status === 200) {
        setListUsers(users.data);
      }
    }
    loadUsers();
  }, []);

  const toggleModalCreatePassword = useCallback(() => {
    setModalCreatePassword(!modalCreatePassword);
  }, [modalCreatePassword]);

  const handleCreatePassword = useCallback(
    (row) => {
      setUser(row.original);
      setModalCreatePassword(!modalCreatePassword);
    },
    [modalCreatePassword]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          resetPassword: Yup.string().min(
            6,
            "A senha deve possuir no mínimo 6 digitos"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (user) {
          const result = await api.post("/users/save-password", {
            userId: user.id,
            password: data.resetPassword,
          });
          if (result.status === 200) {
            const message = {
              place: "tr",
              type: "success",
              title: "Sucesso",
              description: "Senha salva com sucesso!",
              icon: "tim-icons icon-check-2",
            };
            addToast(message);
            formRef.current.reset();
            setModalCreatePassword(!modalCreatePassword);
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current.setErrors(errors);
          return;
        }
        const message = {
          place: "tr",
          type: "danger",
          title: "Error",
          description: "Erro ao salvar senha!",
          icon: "tim-icons icon-alert-circle-exc",
        };
        addToast(message);
      }
    },
    [addToast, modalCreatePassword, user]
  );

  const colunms = [
    {
      Header: "Nome",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "Usuário Flow",
      accessor: "userName",
      headerClassName: "text-center",
    },
    {
      Header: "Email",
      accessor: "email",
      headerClassName: "text-center",
    },
    {
      Header: "status",
      accessor: "isActive",
      headerClassName: "text-center",
    },
    {
      Header: "deleted",
      accessor: "isDeleted",
      headerClassName: "text-center",
    },
    {
      Header: "Senha Hub",
      accessor: "passwordHub",
      headerClassName: "text-center",
    },
    {
      Header: "Cadastrar Senha",
      accessor: "actions",
      Cell: (row) => (
        <div>
          <Button
            className="btn-icon btn-round btn-success"
            onClick={() => handleCreatePassword(row)}
          >
            <i className="tim-icons icon-simple-add" />
          </Button>
        </div>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
      {/* Modal Disable User*/}
      <Modal isOpen={modalCreatePassword} toggle={toggleModalCreatePassword}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalCreatePassword}
          >
            <i className="tim-icons icon-simple-remove text-white" />
          </button>
          <ModalHeader tag="h3">Cadastrar senha</ModalHeader>
        </div>
        <ModalBody className="text-center">
          <Row>
            <Col className="text-center">
              {user && user.passwordHub === "Senha cadastrada" ? (
                <p>
                  Usuário já possui uma senha cadastrada, deseja resetar a
                  senha?
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto mt-2" sm="12" md="12" lg="12">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Col md="12">
                  <CustomInput
                    style={{ color: "#000" }}
                    name="resetPassword"
                    type="text"
                    placeholder="Digite uma senha para acesso ao Hub"
                  />
                </Col>
                <Col className="text-center mt-2">
                  <Button color="success" type="submit">
                    Confirmar
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* End Modal Disable User*/}

      <Row>
        <Col className="ml-auto mr-auto">
          <h2 className="text-center">Usuários Cadastrados</h2>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody className="text-center">
            <ReactTable
              data={listUsers}
              resizable={true}
              columns={colunms}
              defaultPageSize={5}
              showPaginationBottom
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado a ser exibido"
              pageText="Página"
              ofText="do"
              rowsText="linhas"
              rowsSelectorText="linhas por página"
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default Users;
