import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";

import api from "../../../../../services/api";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { unitFormat } from "../../../../../utils/unitFormat";

export default function ChartFaturasAuditoria() {
  const [totalizadores, setTotalizadores] = useState([]);

  useEffect(() => {
    async function loadTotalizadores() {
      try {
        const arrayT = [];

        const responseTotalizadores = await api.get(
          "/dashboard/auditoria/faturas"
        );

        if (responseTotalizadores.status === 200) {
          arrayT.push({
            titulo: "Faturas Inseridas",
            subtitulo: "Hoje",
            valor: unitFormat(responseTotalizadores.data.auditQtdHoje),
            icon: (
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-cloud-upload-94" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Valores Inseridos",
            subtitulo: "Hoje",
            valor: formatCurrency(responseTotalizadores.data.auditValHoje),
            icon: (
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-cloud-upload-94" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Faturas Auditadas",
            subtitulo: "Hoje",
            valor: unitFormat(responseTotalizadores.data.releaseQtdHoje),
            icon: (
              <div className="info-icon text-center icon-info">
                <i className="tim-icons icon-money-coins" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Valores Auditados",
            subtitulo: "Hoje",
            valor: formatCurrency(responseTotalizadores.data.releaseValHoje),
            icon: (
              <div className="info-icon text-center icon-info">
                <i className="tim-icons icon-money-coins" />
              </div>
            ),
          });
        }

        setTotalizadores(arrayT);
      } catch (error) {
        setTotalizadores([]);
      }
    }
    loadTotalizadores();
  }, []);

  return (
    <Row>
      {totalizadores.map((totalizador, index) => (
        <Col lg="3" md="6" key={index.toString()}>
          <Card
            style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
            className="card-stats"
          >
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    {totalizador.icon}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <CardTitle tag="h4">{totalizador.titulo}</CardTitle>
                    <CardTitle tag="h3">{totalizador.valor}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="tim-icons icon-calendar-60" />
                {totalizador.subtitulo}
              </div>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
