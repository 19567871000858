import React, { useEffect, useRef, useCallback, useState } from "react";
import { useField } from "@unform/core";
import classnames from "classnames";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

const CustomInputGroup = ({
  name,
  placeholder,
  type,
  addonType,
  icon,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [inputState, setInputState] = useState("");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    if (error) {
      setInputState("has-danger");
    } else {
      setInputState("");
    }
  }, [error]);

  return (
    <>
      <InputGroup
        className={classnames(inputState, {
          "input-group-focus": isFocused,
        })}
      >
        <InputGroupAddon addonType={addonType}>
          <InputGroupText>
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          innerRef={inputRef}
          type={type}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </InputGroup>
      {inputState === "has-danger" ? (
        <label style={{ color: "#ec250d" }}>{error}</label>
      ) : null}
    </>
  );
};

export default CustomInputGroup;
