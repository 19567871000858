/* eslint-disable no-nested-ternary */
const dev = {
  NAME: "Development Mode",
  API_URL: "http://localhost:8091",
  // API_URL: "https://dashapi.everlog.tech",
  API_HUB: "http://localhost:8080/api",
  // API_HUB: "https://hub-production.everlog.tech/api",
  // API_PDT: "https://api-sgq.everlog.tech:8443",
  API_PDT: "http://localhost:8091",
  // API_PDT: "https://dashapi.everlog.tech",
  // API_HUB: "https://hub-production-staging.everlog.tech/api",
  // API_PDT: "https://api-sqg-hom.everlog.tech:8443",
};

const staging = {
  NAME: "Staging Mode",
  API_URL: "https://api-integration-flow-staging.everlog.tech",
  API_HUB: "https://hub-backend-staging.everlog.tech/api",
  API_PDT: "https://dashapi.everlog.tech",
  // API_PDT: "https://api-sqg-hom.everlog.tech:8443",
};

const prod = {
  NAME: "Production",
  API_URL: "https://dashapi.everlog.tech",
  API_HUB: "https://hub-production.everlog.tech/api",
  API_PDT: "https://dashapi.everlog.tech",
  // API_PDT: "http://localhost:8091",
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
