import React, { useState, useEffect } from "react";
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import ReactTable from "react-table";

import apiPortalTransportador from "services/apiPortalTransportador";


import { ContainerTable, Container, ContainerButtonsModal } from "./styles";

const Transportadoras = () => {
  const [openModal, setOpenModal] = useState(false);

  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(false);

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [newAlert, setNewAlert] = useState({
    text: "",
    open: false,
    type: "info",
  });

  const openAlert = (text = "", type = "info") => {
    setNewAlert({
      text: text,
      open: !newAlert.open,
      type: type,
    });
  };

  const colunms = [
    {
      Header: "Fantasia",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "CNPJ",
      accessor: "cnpj",
      headerClassName: "text-center",
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => (
        <div>
          <button
            className="btn-icon btn-round btn-success"
            style={{ margin: "3px" }}
            onClick={() => {
              setCnpj(row.original.cnpj);
              setNomeFantasia(row.original.name);
              setId(row.original.idShipper);
              setEdit(true);
              toggle();
            }}
          >
            <i className="tim-icons icon-pencil" />
          </button>
          <button
            className="btn-icon btn-round btn-danger"
            style={{ margin: "3px" }}
            onClick={() => {
              setNomeFantasia(row.original.name);
              setCnpj(row.original.cnpj);
              toggleDelete();
            }}
          >
            <i className="tim-icons icon-trash-simple" />
          </button>
        </div>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const [shippers, setShippers] = useState([]);

  const getAllShippers = async () => {
    const { data } = await apiPortalTransportador.get("/shippers");
    data && setShippers(data.reverse());
  };

  const createNewShipper = async () => {
    if (nomeFantasia.length < 1) {
      return openAlert("Não é permitido o uso de nome em branco", "danger");
    }

    if (cnpj.length < 1) {
      return openAlert("Não é permitido o uso de CNPJ em branco", "danger");
    }

    apiPortalTransportador
      .post("/shippers", {
        name: nomeFantasia,
        cnpj: cnpj,
      })
      .then(() => {
        openAlert("Transportadora criada com sucesso!", "success");
        setNomeFantasia("");
        setCnpj("");
        getAllShippers();
        toggle();
      });
  };

  const editShipper = async () => {
    if (nomeFantasia.length < 1) {
      return openAlert("Não é permitido o uso de nome em branco", "danger");
    }

    if (cnpj.length < 1) {
      return openAlert("Não é permitido o uso de CNPJ em branco", "danger");
    }

    apiPortalTransportador
      .put("/shippers", {
        name: nomeFantasia,
        cnpj: cnpj,
        idShipper: id,
      })
      .then(() => {
        openAlert("Transportadora alterada com sucesso!", "success");
        setNomeFantasia("");
        setCnpj("");
        setEdit(false);
        getAllShippers();
        toggle();
      });
  };

  const removeShipper = () => {
    apiPortalTransportador
      .delete(`/shippers/${cnpj}`)
      .then(() => {
        getAllShippers();
        setNomeFantasia("");
        setCnpj("");
        openAlert("Transportadora deletada com sucesso!", "success");
        toggleDelete();
      })
      .catch((e) => {
        openAlert(
          "Transportadora com relacionamentos a serem desvinculado",
          "danger"
        );
      });
  };

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const toggleDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  useEffect(() => {
    getAllShippers();
  }, []);

  return (
    <Container className="content">
      <Alert
        color={newAlert.type}
        isOpen={newAlert.open}
        toggle={() => openAlert("", "")}
      >
        {newAlert.text}
      </Alert>
      <Row>
        <h1>Transportadoras</h1>
      </Row>
      <Row>
        <button
          type="button"
          class="btn btn-primary btn-round"
          onClick={toggle}
        >
          Novo
        </button>
      </Row>
      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2 class="text-dark">{edit ? "Editar" : "Criar"} transportadora</h2>
        </ModalHeader>
        <ModalBody>
          <div class="form-group">
            <label for="fantasia">Fantasia</label>
            <input
              value={nomeFantasia}
              class="form-control text-dark"
              id="fantasia"
              placeholder="Nome fantasia"
              onChange={(e) => setNomeFantasia(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input
              value={cnpj}
              class="form-control text-dark"
              id="cnpj"
              placeholder="0.000.000/0000-00"
              onChange={(e) => setCnpj(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={edit ? () => editShipper() : () => createNewShipper()}
            >
              Salvar
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      {/* Modal de deletar */}

      <Modal isOpen={openModalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={toggle}>
          <h2 class="text-dark">{nomeFantasia}</h2>
        </ModalHeader>
        <ModalBody>
          <h2 class="text-dark">
            Você realmente quer excluir a transportadora {nomeFantasia}?
          </h2>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={toggleDelete}
            >
              Não
            </button>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={removeShipper}
            >
              Sim
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <ContainerTable>
        <Row>
          <Card>
            <CardBody className="text-center">
              <ReactTable
                data={shippers}
                resizable={true}
                columns={colunms}
                defaultPageSize={5}
                showPaginationBottom
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado a ser exibido"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                rowsSelectorText="linhas por página"
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Row>
      </ContainerTable>
    </Container>
  );
};

export default Transportadoras;
