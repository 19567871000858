import Dashboard from "../views/Transportadora/Dashboard";
import Extrato from "../views/Transportadora/Extrato";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/app",
    authorized: "Pages.HUB.Dashboard",
    setor: "transportadora",
  },
  {
    path: "/extrato",
    name: "Extrato",
    icon: "tim-icons icon-notes",
    component: Extrato,
    layout: "/app",
    authorized: "Pages.HUB.Extrato",
  },
];

export default routes;
