import Dashboard from "../views/Empresa/Dashboard";
import Fatura from "views/Empresa/Fatura";
import Cte from "views/Empresa/Cte";
import NotaFiscal from "views/Empresa/NotaFiscal";
import Pedido from "views/Empresa/Pedido";
import GoBID from "views/Empresa/GoBID";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-bar-32",
    component: Dashboard,
    layout: "/app",
    authorized: "Pages.HUB.Dashboard",
    setor: "empresa",
  },
  {
    collapse: true,
    name: "Monitoramento",
    icon: "tim-icons icon-bullet-list-67",
    state: "relatoriosCollapse",
    authorized: null,
    views: [
      {
        path: "/faturas",
        name: "Faturas",
        mini: "FAT",
        component: Fatura,
        layout: "/app",
        authorized: "Pages.HUB.Monitoramento.Faturas",
      },
      {
        path: "/ctes",
        name: "CTE'S",
        mini: "CTE",
        component: Cte,
        layout: "/app",
        authorized: "Pages.HUB.Monitoramento.Cets",
      },
      {
        path: "/notasFiscais",
        name: "Notas Fiscais",
        mini: "NFS",
        component: NotaFiscal,
        layout: "/app",
        authorized: "Pages.HUB.Monitoramento.NotasFiscais",
      },
    ],
  },
  {
    path: "/pedidos",
    name: "Pedidos",
    icon: "tim-icons icon-cart",
    component: Pedido,
    layout: "/app",
    authorized: "Pages.HUB.Pedidos",
  },
  {
    path: "/gobid",
    name: "Gobid",
    icon: "tim-icons icon-delivery-fast",
    component: GoBID,
    layout: "/app",
    authorized: "Pages.HUB.GoBID",
  },
];

export default routes;
