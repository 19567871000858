import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import api from "../../../../../services/api";
import { useTheme } from "../../../../../hooks/theme";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { unitFormat } from "../../../../../utils/unitFormat";

export default function ChartFaturaSituacao() {
  const { darkMode } = useTheme();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const response = await api.get(
          "/dashboard/auditoria/qtd/situacao/total"
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, []);

  // The CustomTooltip function takes in an object and returns a div element.
  // The returned div element contains a p element.
  // The p element contains the formatted value of the payload.

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;
      const payFormatted = payload.map((pay) => ({
        name: pay.name,
        value: unitFormat(pay.payload.qtd),
      }));
      console.log(payFormatted);
      return (
        <div
          className="tooltip-inner"
          style={{
            background: darkMode ? "#e9ecef" : "#1e1e2f",
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          <p style={{ color: darkMode ? "#000" : "#fff", fontWeight: 100 }}>
            <strong>{`${payFormatted[0].name}: `}</strong>
            {payFormatted[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  const maxYValue = Math.max(...data.map((item) => item.qtd));
  const extendedYValue = maxYValue + 3;

  return (
    <Card
      style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
      className="card-chart"
    >
      <CardHeader>
        <h4>Faturas em Auditoria</h4>
        <CardTitle>Por Situação</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            {data.length > 0 ? (
              <BarChart
                data={data}
                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
              >
                <defs>
                  <linearGradient id="qtd" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#ffd600" stopOpacity={0.3} />
                    <stop offset="90%" stopColor="#ffd600" stopOpacity={0.1} />
                    <stop offset="90%" stopColor="#ffd600" stopOpacity={0.1} />
                  </linearGradient>
                  {/* <linearGradient id="imposto" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#f5365c" stopOpacity={0.3} />
                    <stop offset="90%" stopColor="#f5365c" stopOpacity={0.1} />
                    <stop offset="90%" stopColor="#f5365c" stopOpacity={0.1} />
                  </linearGradient> */}
                </defs>
                <CartesianGrid
                  stroke={
                    darkMode ? "rgba(255, 214, 0, 0.1)" : "rgba(0, 0, 0, 0.1)"
                  }
                  strokeWidth={1}
                />
                <XAxis
                  dataKey="nome"
                  stroke={darkMode ? "#fff" : "#000"}
                  style={{ fontWeight: 50 }}
                />
                <YAxis
                  tickFormatter={(tick) => unitFormat(tick)}
                  stroke={darkMode ? "#fff" : "#000"}
                  style={{ fontWeight: 100 }}
                  width={10}
                  padding={{ right: 50 }}
                  domain={[0, extendedYValue]}
                />
                <Tooltip content={(props) => CustomTooltip(props)} />
                <Legend />
                <Bar
                  dataKey="qtd"
                  name="Quantidade"
                  type="monotone"
                  stroke="#ffd600"
                  fillOpacity={1}
                  fill="url(#qtd)"
                />
                {/* <Bar
                  dataKey="imposto"
                  type="monotone"
                  stroke="#f5365c"
                  fillOpacity={1}
                  fill="url(#imposto)"
                /> */}
              </BarChart>
            ) : (
              <h5>Nenhum dado para apresentar!</h5>
            )}
          </ResponsiveContainer>
        )}
      </CardBody>
    </Card>
  );
}
