import React, { createContext, useCallback, useState, useContext } from "react";
import moment from "moment";
import api from "../services/api";
import apiPortalTransportador from "../services/apiPortalTransportador";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@HubEverlog:token");
    const user = localStorage.getItem("@HubEverlog:user");

    if (token && user) {
      const userParsed = JSON.parse(user);
      const momentNow = moment();
      const tokenExpiration = moment.unix(userParsed.exp);
      if (momentNow.isSameOrAfter(tokenExpiration)) {
        localStorage.removeItem("@HubEverlog:token");
        localStorage.removeItem("@HubEverlog:user");
        return {};
      }
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: userParsed,
      };
    }

    return {};
  });

  const login = useCallback(async ({ username, password }) => {
    const response = await api.post("sessions", {
      username,
      password,
    });

    const { token, user } = response.data;

    // console.log("Token e ID do usuário");
    // console.log(token);
    // console.log(user.id);

    localStorage.setItem("@HubEverlog:token", token);

    api.defaults.headers.authorization = `Bearer ${token}`;
    var apiPortalTransportadorClone = apiPortalTransportador;
    apiPortalTransportadorClone.defaults.headers.Authorization = `Bearer ${token}`;

    // console.log("Token do storage");
    // console.log(localStorage.getItem("@HubEverlog:token"));

    if (user) {
      const permission = await apiPortalTransportadorClone.get(
        `/UsersFlow/permissionHub/${user.id}`
      );

      // console.log(permission.status);
      // console.log(permission.data.length);

      if (permission.status === 200 && permission.data.length > 0) {
        // console.log("Permissões do usuário");
        // console.log(permission.data);
        const { data } = permission;
        const newUser = { ...user, permissions: data };
        localStorage.setItem("@HubEverlog:user", JSON.stringify(newUser));
        setData({ token, user: newUser });
      }
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("@HubEverlog:token");
    localStorage.removeItem("@HubEverlog:user");
    setData({});
  }, []);
  return (
    <AuthContext.Provider value={{ user: data.user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// hook de autenticação
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
