import React, { useEffect, useState, useCallback, useRef } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ReactTable from "react-table";
import { ClipLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import fileDownload from "js-file-download";

import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import FileUpload from "../../../components/Input/fileUpload";
import Status from "../../../components/Status";

import { useCompanies } from "hooks/companies";

const urlGet = "/embarcadores/feriados";
const urlGetExportCsv = "/embarcadores/feriados/export-csv";
const urlPostImportCsv = "/embarcadores/feriados/import-csv";

const Holidays = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertRef = useRef();
  const { addToast } = useToast();

  const { empresa } = useCompanies();

  useEffect(() => {
    setData([]);
    if (modal === false) {
      async function loadData() {
        try {
          setLoading(true);
          const result = await api.get(urlGet, {
            params: { idEmbarcadorRemetente: empresa.value },
          });
          if (result.status === 200) {
            setData(result.data);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      loadData();
    }
  }, [empresa, modal]);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "content-type": "application/octet-stream",
          "Content-Disposition": "attachment;filename=feriados.csv",
        },
      };
      const result = await api.get(
        urlGetExportCsv,
        {
          params: { idEmbarcadorRemetente: empresa.value },
        },
        config
      );
      if (result.status === 200) {
        fileDownload(result.data, "file.csv");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const message = {
        place: "tr",
        type: "danger",
        title: "Error",
        description: "Erro ao baixar planilha!",
        icon: "tim-icons icon-alert-circle-exc",
      };
      addToast(message);
    }
  };

  const colunms = [
    {
      Header: "Data Feriado",
      accessor: "data",
      headerClassName: "text-center",
    },
    {
      Header: "Tipo",
      accessor: "tipo",
      headerClassName: "text-center",
    },
    {
      Header: "UF",
      accessor: "estado",
      headerClassName: "text-center",
    },
    {
      Header: "Cidade",
      accessor: "cidade",
      headerClassName: "text-center",
    },
    {
      Header: "Nome",
      accessor: "nome",
      headerClassName: "text-center",
    },
    {
      Header: "Descrição",
      accessor: "descricao",
      headerClassName: "text-center",
    },
    {
      Header: "Status",
      accessor: "status",
      headerClassName: "text-center",
      Cell: (row) => (
        <>
          {Status({
            status: row.original.status ? "Ativo" : "Inativo",
            title: row.original.status ? "Ativo" : "Inativo",
          })}
        </>
      ),
    },
  ];

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <i className="tim-icons icon-simple-remove text-black" />
          </button>
          <ModalHeader tag="h3">Enviar planilha</ModalHeader>
        </div>
        <ModalBody className="text-center">
          <Row>
            <Col className="text-center">
              <FileUpload
                api={api}
                url={urlPostImportCsv}
                params={{ idEmbarcadorRemetente: empresa.value }}
                paramsFormData={null}
                accept=".csv"
                filekey="file"
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Row>
        <Col className="ml-auto mr-auto">
          <h2 className="text-center">Feriados Cadastrados</h2>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto">
          <Button
            className="btn btn-round btn-success"
            onClick={() => handleDownload()}
          >
            <FaFileDownload size={25} />
            {"  "} Baixar planilha
          </Button>

          <Button
            className="btn btn-round btn-success"
            onClick={() => setModal(!modal)}
          >
            <FaFileUpload size={25} />
            {"  "} Enviar planilha
          </Button>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody className="text-center">
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <ClipLoader
                  sizeUnit="px"
                  size={90}
                  color="#1d8cf8"
                  loading={loading}
                />
                <br />
                <br />
                <h1 className="text-info" style={{ textAlign: "center" }}>
                  Carregando ...
                </h1>
              </div>
            ) : (
              <ReactTable
                data={data}
                resizable={true}
                columns={colunms}
                defaultPageSize={5}
                showPaginationBottom
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado a ser exibido"
                pageText="Página"
                ofText="do"
                rowsText="linhas"
                rowsSelectorText="linhas por página"
                className="-striped -highlight"
              />
            )}
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default Holidays;
