import React, { useCallback, useState } from "react";
import Switch from "react-bootstrap-switch";
import { useTheme } from "../../hooks/theme";
import { useLegend } from "../../hooks/legend";

const FixedPlugin = (props) => {
  const [classes, setClasses] = useState("dropdown");
  const { darkMode, setTheme } = useTheme();
  const { legendMode, setLegend } = useLegend();

  const handleClick = useCallback(() => {
    if (classes === "dropdown") {
      setClasses("dropdown show");
    } else {
      setClasses("dropdown");
    }
  }, [classes]);

  const handleActiveMode = useCallback(() => {
    setTheme(!darkMode);
    document.body.classList.toggle("white-content");
  }, [darkMode, setTheme]);

  const handleLegendMode = useCallback(() => {
    setLegend(!legendMode);
  }, [legendMode, setLegend]);

  return (
    <div className="fixed-plugin">
      <div className={classes}>
        <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <i className="fa fa-cog fa-2x" />
        </a>
        <ul className="dropdown-menu show">
          <li className="header-title">SIDEBAR BACKGROUND</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={
                  props.activeColor === "primary"
                    ? "badge filter badge-primary active"
                    : "badge filter badge-primary"
                }
                data-color="primary"
                onClick={() => {
                  props.handleActiveClick("primary");
                }}
              />
              <span
                className={
                  props.activeColor === "blue"
                    ? "badge filter badge-info active"
                    : "badge filter badge-info"
                }
                data-color="info"
                onClick={() => {
                  props.handleActiveClick("blue");
                }}
              />
              <span
                className={
                  props.activeColor === "green"
                    ? "badge filter badge-success active"
                    : "badge filter badge-success"
                }
                data-color="success"
                onClick={() => {
                  props.handleActiveClick("green");
                }}
              />
              <span
                className={
                  props.activeColor === "orange"
                    ? "badge filter badge-warning active"
                    : "badge filter badge-warning"
                }
                data-color="warning"
                onClick={() => {
                  props.handleActiveClick("orange");
                }}
              />
              <span
                className={
                  props.activeColor === "red"
                    ? "badge filter badge-danger active"
                    : "badge filter badge-danger"
                }
                data-color="danger"
                onClick={() => {
                  props.handleActiveClick("red");
                }}
              />
            </div>
          </li>
          <li className="header-title">SIDEBAR MINI</li>
          <li className="adjustments-line">
            <div className="togglebutton switch-sidebar-mini">
              <span className="label-switch">OFF</span>
              <Switch
                onChange={props.handleMiniClick}
                value={props.sidebarMini}
                onText=""
                offText=""
              />
              <span className="label-switch">ON</span>
            </div>
          </li>
          <li className="adjustments-line">
            <div className="togglebutton switch-change-color mt-3">
              <span className="label-switch">LIGHT MODE</span>
              <Switch
                onChange={handleActiveMode}
                value={darkMode}
                onText=""
                offText=""
              />
              <span className="label-switch">DARK MODE</span>
            </div>
          </li>
          <li className="header-title">MOSTRAR LEGENDA</li>
          <li className="adjustments-line">
            <div className="togglebutton switch-sidebar-mini">
              <span className="label-switch">OFF</span>
              <Switch
                onChange={handleLegendMode}
                value={legendMode}
                onText=""
                offText=""
              />
              <span className="label-switch">ON</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FixedPlugin;
