import React from "react";
import { Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function TableCtes({ data }) {
  const formatterFatura = (cell, row) => {
    return (
      <>
        {row.numeroFatura === "Não Faturado" ? (
          <Badge pill color="primary">
            {row.numeroFatura}
          </Badge>
        ) : (
          <Badge pill color="info">
            {row.numeroFatura}
          </Badge>
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: "numeroCte",
      text: "Nº Cte",
    },
    {
      dataField: "notasFiscais",
      text: "Notas",
    },
    {
      dataField: "totalFrete",
      text: "Valor",
    },
    {
      dataField: "dataEmissao",
      text: "Data Emissão",
    },
    {
      dataField: "numeroFatura",
      text: "Fatura",
      formatter: formatterFatura,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} resultados
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "Primeira",
    prePageText: "Voltar",
    nextPageText: "Proxímo",
    lastPageText: "Última",
    nextPageTitle: "Primeira página",
    prePageTitle: "Pré página",
    firstPageTitle: "Próxima página",
    lastPageTitle: "Última página",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <BootstrapTable
      keyField="numeroCte"
      data={data}
      columns={columns}
      bordered={false}
      classes="text-center"
      pagination={paginationFactory(options)}
    />
  );
}
