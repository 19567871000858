import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import api from "../../../../../services/api";
import { useTheme } from "../../../../../hooks/theme";
import { useCompanies } from "../../../../../hooks/companies";
import ModalFilters from "../../../../../components/CustomModalFilters";

const COLORS = [
  "#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#9400D3", 
  "#4CAF50", "#E91E63", "#9C27B0", "#FF5722", "#03A9F4", 
  "#795548", "#CDDC39", "#FFEB3B", "#FF9800", "#2196F3",
  "#3F51B5", "#673AB7", "#00BCD4", "#009688", "#8BC34A",
  "#FFC107", "#FF5722", "#607D8B", "#FF4500", "#ADFF2F", 
  "#40E0D0", "#DA70D6", "#FF1493", "#1E90FF", "#FFD700"
];

// Função de label para exibir apenas percentuais acima de um certo limite
const renderCustomLabel = ({ sigla, percentual }) => {
  return percentual > 2 ? `${sigla}: ${percentual}%` : null;
};

export default function ChartFretePorUF() {
  const { darkMode } = useTheme();
  const { empresa, transportadora } = useCompanies();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);

        const params = {
          dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
          dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
        };

        if (empresa) {
          params.embarcadores = [empresa.value];
        }

        if (transportadora) {
          params.transportadoras = [transportadora.value];
        }

        const response = await api.get("/dashboard/custo/frete-por-uf", {
          params,
        });

        if (response.status === 200) {
          const totalFrete = response.data.reduce(
            (acc, item) => acc + item.valor,
            0
          );

          const dataWithPercentage = response.data
            .filter((item) => item.valor > 0)
            .map((item) => ({
              ...item,
              percentual: Number(((item.valor / totalFrete) * 100).toFixed(2)),
            }));

          setData(dataWithPercentage);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    if (empresa) {
      loadCharts();
    }
  }, [auxDtFinal, auxDtInicial, empresa, transportadora]);

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }} className="card-chart">
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <h4>Frete por UF</h4>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button className="btn-sm" color="info" onClick={toggleModalFilters}>
              Período
            </Button>
          </Col>
        </Row>
        <CardTitle>Percentual de Frete por Estado</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader sizeUnit="px" size={90} color="#1d8cf8" loading={loading} />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : data.length === 0 ? (
          <h5 style={{ textAlign: "center", color: "#FFFFFF" }}>
            Nenhum dado para exibir
          </h5>
        ) : (
          <Row className="justify-content-center">
            <Col style={{ textAlign: "center" }}>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={data}
                    dataKey="percentual"
                    nameKey="sigla"
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="80%"
                    label={renderCustomLabel} // Usando a função de label condicional
                    labelLine={true} // Adiciona linha de ligação para as labels
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}