import Login from '../views/pages/Login';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
];

export default routes;
