import React from "react";
import { Row, Col, Badge, Button } from "reactstrap";
import Scrollbar from "react-scrollbars-custom";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import moment from "moment";

import imgCte from "../../../../../assets/img/cte.png";

export default function ListCtes({ listCtes, tableCtes }) {
  return (
    <Scrollbar style={{ width: "100%", height: 457 }}>
      <div style={{ paddingRight: "20px", paddingLeft: "1px" }}>
        <ul className="timeline timeline-simple" style={{ marginLeft: "2px" }}>
          {listCtes.map((item, index) => (
            <li key={index.toString()} className="timeline-inverted">
              <div
                className="timeline-badge"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={imgCte}
                  alt="cte"
                  style={{ width: "41px", alignItems: "center" }}
                />
              </div>
              <div
                className="timeline-panel"
                style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
              >
                <div className="timeline-heading">
                  <Row>
                    <Col md="8">
                      <p>
                        Total do dia:{" "}
                        <strong>{formatCurrency(item.total_frete_dia)}</strong>
                      </p>
                      <p>
                        Faturado:{" "}
                        <strong style={{ color: "#32cd32" }}>
                          {formatCurrency(item.total_faturado_dia)}
                        </strong>
                      </p>
                      <p>
                        Não Faturado:{" "}
                        <strong style={{ color: "#1d8cf8" }}>
                          {formatCurrency(
                            item.total_nao_faturado_dia
                              ? item.total_nao_faturado_dia
                              : 0
                          )}
                        </strong>
                      </p>
                    </Col>
                    <Col className="text-right">
                      <Badge style={{ backgroundColor: "#ffd600" }}>
                        {moment(item.dias).format("DD - MMM")}
                      </Badge>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="timeline-body">
                  <Row>
                    <Col>
                      <p>
                        Qtd de ctes:{" "}
                        <strong style={{ color: "#ffd600" }}>
                          {item.qtd_ctes}
                        </strong>
                      </p>
                    </Col>
                    <Col className="text-right">
                      <Button
                        className="btn-round btn-sm"
                        color="success"
                        onClick={() => tableCtes(item.dias)}
                      >
                        Ver Ctes
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Scrollbar>
  );
}
