// CustomTable.jsx
import React from "react";
import {
  Row,
  Col,
  CardTitle,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const CustomTable = ({
  data,
  columns,
  fileName,
  tableName,
  loading,
  setStatusFilter,
  handleSimulateFrete,
}) => {
  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} resultados
    </span>
  );

  const paginationOptions = {
    sizePerPage: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
    firstPageText: "Primeira",
    prePageText: "Voltar",
    nextPageText: "Próximo",
    lastPageText: "Última",
    nextPageTitle: "Primeira página",
    prePageTitle: "Pré página",
    firstPageTitle: "Próxima página",
    lastPageTitle: "Última página",
    showTotal: true,
    paginationTotalRenderer: customTotal,
  };

  const csvOptions = {
    onlyExportFiltered: true,
    exportAll: true,
    fileName,
  };

  const orderIcon = (order) => {
    if (!order)
      return (
        <span>
          &nbsp;&nbsp;
          <i className="tim-icons icon-minimal-down" />
          <i className="tim-icons icon-minimal-up" />
        </span>
      );
    if (order === "asc")
      return (
        <span>
          &nbsp;
          <font color="red">
            <i className="tim-icons icon-minimal-up" />
          </font>
        </span>
      );
    if (order === "desc")
      return (
        <span>
          &nbsp;
          <font color="red">
            <i className="tim-icons icon-minimal-down" />
          </font>
        </span>
      );
    return null;
  };

  const columnsFormatted = columns.map((col) => ({
    dataField: col.dataField,
    text: col.text,
    sort: true,
    hidden: col.dataField === "id",
    sortCaret: (order, column) => {
      return orderIcon(order, column);
    },
    formatter: col.formatter,
  }));

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader sizeUnit="px" size={90} color="#1d8cf8" loading={loading} />
        <br />
        <br />
        <h1 className="text-info" style={{ textAlign: "center" }}>
          Carregando ...
        </h1>
      </div>
    );
  }
  return (
    <ToolkitProvider
      keyField="_id"
      data={data}
      columns={columnsFormatted}
      exportCSV={csvOptions}
      search={{ searchFormatted: true }}
    >
      {(prop) => (
        <>
          <Row style={{ alignItems: "center" }}>
            <Col md={3}>
              <CardTitle tag="h4">
                <i className="tim-icons icon-spaceship" /> {tableName}
              </CardTitle>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <SearchBar {...prop.searchProps} placeholder="Pesquisar" />
              <Button
                className="btn btn-warning"
                onClick={prop.searchProps.onClear}
              >
                Limpar
              </Button>
              <Button className="btn btn-success" onClick={handleSimulateFrete}>
                Simular Frete
              </Button>
              <Button
                className="btn btn-primary"
                onClick={() => setStatusFilter("Pendente")}
              >
                Pendente
              </Button>
              <Button
                className="btn btn-info"
                onClick={() => setStatusFilter("Simulado")}
              >
                Simulado
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={() => setStatusFilter("")}
              >
                Todos
              </Button>
              <div className="tools float-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-link btn-icon"
                    color="default"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Baixar planilha
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Enviar planilha
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else here
                    </DropdownItem>
                    <DropdownItem
                      className="text-danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Deletar
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
          <hr />
          <BootstrapTable
            {...prop.baseProps}
            noDataIndication="Consulta não retornou dados, altere a consulta para obter um novo resultado."
            bordered={false}
            striped
            classes="text-center"
            pagination={paginationFactory(paginationOptions)}
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default CustomTable;
