import React from "react";
import { AuthProvider } from "./auth";
import { ToastProvider } from "./toast";
import { CompaniesProvider } from "./companies";
import { ThemeProvider } from "./theme";
import { LegendProvider } from "./legend";
import { DateFilterProvider } from "./datefilter"; // Importe o DateFilterProvider

const AppProvider = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <LegendProvider>
        <DateFilterProvider> {/* Adicione o DateFilterProvider aqui */}
          <CompaniesProvider>
            <ToastProvider>{children}</ToastProvider>
          </CompaniesProvider>
        </DateFilterProvider>
      </LegendProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default AppProvider;