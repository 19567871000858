import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Collapse,
  Button,
} from "reactstrap";
import "../../../../../assets/css/black-dashboard-pro-react.css";
import api from "../../../../../services/api";
import { useTheme } from "../../../../../hooks/theme";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { formatDate } from "../../../../../utils/formatDate";
import moment from "moment";

const getCardColor = (dataVctoFatura, statusFatura, numeroFat) => {
  // Verifica se o status é "Integrada ERP" e aplica a cor padrão
  if (statusFatura === "FaturaPaga") {
    return "card-padrao";
  }

  const now = moment();
  const vencimento = moment(dataVctoFatura);
  const diasParaVencimento = vencimento.diff(now, 'days');

  if (diasParaVencimento < 0) {
    return "card-vencido"; // Vencido - Vermelho
  } else if (diasParaVencimento <= 7) {
    return "card-vai-vencer-7"; // Vai vencer em 7 dias - Laranja
  } else if (diasParaVencimento <= 15) {
    return "card-vai-vencer-15"; // Vai vencer em 15 dias - Amarelo
  } else if (diasParaVencimento <= 30) {
    return "card-vai-vencer-30"; // Vai vencer em 30 dias - Azul
  } else if (diasParaVencimento > 30) {
    return "card-futuro"; // Vencimento em mais de 30 dias - Verde
  } else {
    return "card-padrao"; // Qualquer outra condição padrão
  }
};

const KanbanCard = ({ fatura, toggleCard, openCard }) => {
  const { darkMode } = useTheme();



  return (
    <Card className={`mb-2 kanban-card ${getCardColor(fatura.DataVctoFatura, fatura.StatusFatura, fatura.NumeroFat)}`}>
      <CardHeader
        onClick={() => toggleCard(fatura.id)}
        className="kanban-card-header"
        style={{ cursor: "pointer" }}
      >
      <h5 className="card-title" style={{ fontWeight: "bold" }}>
        {fatura.Transportadora}
      </h5>
      </CardHeader>
      <CardBody className="kanban-card-body">
        <p>
          <strong>Fatura:</strong> {fatura.NumeroFat}
        </p>
        <p>
          <strong>Vencimento:</strong> {formatDate(fatura.DataVctoFatura)}
        </p>
        <p>
          <strong>Valor:</strong> {formatCurrency(fatura.ValorFat)}
        </p>
        <Collapse isOpen={openCard === fatura.id}>
          <p>
            <strong>Log:</strong> {fatura.log}
          </p>
        </Collapse>
      </CardBody>
    </Card>
  );
};

const KanbanColumn = ({ columnName, faturas, toggleCard, openCard }) => {
  return (
    <div className="kanban-column">
      <h4 className="kanban-title">{columnName}</h4>
      {faturas.map((fatura) => (
        <KanbanCard
          key={fatura.id}
          fatura={fatura}
          toggleCard={toggleCard}
          openCard={openCard}
        />
      ))}
    </div>
  );
};

const KanbanStatusFatura = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [openCard, setOpenCard] = useState(null);
  const [data, setData] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    async function loadFaturas() {
      try {
        setLoading(true);
        const response = await api.get("/dashboard/auditoria/statusfatura");
        if (response.status === 200) {
          // console.log("API Response:", response.data); // Log da resposta da API
          setData(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch faturas", error);
      } finally {
        setLoading(false);
      }
    }
    loadFaturas();
  }, []);

  useEffect(() => {
    const columns = document.querySelectorAll(".kanban-column");
  
    columns.forEach((column) => {
      const handleScroll = () => {
        if (column.scrollTop > 0) {
          column.classList.add("scrolled");
        } else {
          column.classList.remove("scrolled");
        }
      };
  
      column.addEventListener("scroll", handleScroll);
  
      // Cleanup para remover o event listener quando o componente desmontar
      return () => {
        column.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter === activeFilter ? null : filter); // Alterna entre aplicar e remover o filtro
  };

  const toggleCard = (id) => {
    const numericId = Number(id);
    setOpenCard(openCard === numericId ? null : numericId);
  };

  const statuses = {
    "Falta CTe": "FaltaCTe",
    "Falta Viagem": "FaltaNFe",
    "A Validar": "FaturaIntegrada",
    "Com Divergência": "FaturaComDivergencia",
    "Fatura Liberada": "FaturaLiberada",
    "Integrada ERP": "FaturaPaga",
  };

  const filteredData = data.filter((fatura) => {
    if (fatura.NumeroFat === null) {
      console.warn(
        `Found a fatura with null NumeroFat: ${JSON.stringify(fatura)}`
      );
      return false;
    }
    
    // Se houver um filtro ativo, só exibe os cartões que correspondem ao filtro
    if (activeFilter) {
      return getCardColor(fatura.DataVctoFatura) === activeFilter;
    }

    return fatura.NumeroFat.toString().includes(searchTerm);
  });

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Row style={{ alignItems: "center" }}>
            <Col>
              <h2 className="title">Status das Faturas</h2>
              <h4 className="subtitle">Clique nas Legendas para filtrar</h4>
            </Col>
            
            {/* Legenda de cores */}
            <Col style={{ textAlign: "center" }}>
            <div className="kanban-legend">
                  <span 
                    className={`legend-item card-vencido ${activeFilter === "card-vencido" ? "active" : ""}`} 
                    onClick={() => handleFilterClick("card-vencido")}
                    style={{ cursor: "pointer" }}
                  >
                    Fatura já está Vencida
                  </span>
                  <span 
                    className={`legend-item card-vai-vencer-7 ${activeFilter === "card-vai-vencer-7" ? "active" : ""}`} 
                    onClick={() => handleFilterClick("card-vai-vencer-7")}
                    style={{ cursor: "pointer" }}
                  >
                    Vence em 7 dias
                  </span>
                  <span 
                    className={`legend-item card-vai-vencer-15 ${activeFilter === "card-vai-vencer-15" ? "active" : ""}`} 
                    onClick={() => handleFilterClick("card-vai-vencer-15")}
                    style={{ cursor: "pointer" }}
                  >
                    Vence em 15 dias
                  </span>
                  <span 
                    className={`legend-item card-futuro ${activeFilter === "card-futuro" ? "active" : ""}`} 
                    onClick={() => handleFilterClick("card-futuro")}
                    style={{ cursor: "pointer" }}
                  >
                    Vence em Mais de 30 dias
                  </span>
                  <span 
                    className={`legend-item card-padrao ${activeFilter === "card-padrao" ? "active" : ""}`} 
                    onClick={() => handleFilterClick("card-padrao")}
                    style={{ cursor: "pointer" }}
                  >
                    Fatura já foi Integrada
                  </span>
                </div>
            </Col>


            <Col style={{ textAlign: "right" }}>
              <Input
                type="text"
                placeholder="Pesquisar pelo número da fatura"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  display: "inline",
                  width: "auto",
                  marginRight: "10px",
                }}
              />
              <Button className="btn btn-warning" onClick={handleClearSearch}>
                Limpar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <ClipLoader
                sizeUnit="px"
                size={90}
                color="#1d8cf8"
                loading={loading}
              />
              <br />
              <br />
              <h1 className="text-info">Carregando ...</h1>
            </div>
          ) : (
            <Row className="kanban-container" style={{ padding: "20px 0" }}>
            {Object.entries(statuses).map(([columnName, statusFilter]) => (
              <Col
                key={columnName}
                xs="12"
                sm="6"
                md="4"
                lg="2"
                style={{ marginBottom: "20px" }}
              >
                <KanbanColumn
                  columnName={columnName}
                  faturas={filteredData.filter(
                    (fatura) => fatura.StatusFatura === statusFilter
                  )}
                  toggleCard={toggleCard}
                  openCard={openCard}
                />
              </Col>
            ))}
          </Row>
          )}
        </CardBody>
      </Card>
    </div>
  )
};

export default KanbanStatusFatura;
