import React, { useState, useEffect } from "react";
import { Row, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Alert } from "reactstrap";
import ReactTable from "react-table";

import apiPortalTransportador from "services/apiPortalTransportador";

import { ContainerTable, Container, ContainerButtonsModal } from './styles';

const UsuariosPDT = () => {
  const [users, setUsers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [userShippers, setUserShippers] = useState([]);

  const [shippersFilter, setShippersFilter] = useState(shippers || []);

  const [userShipperIntegration, setUserShipperIntegration] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmeSenha, setConfirmeSenha] = useState('');
  const [tipo, setTipo] = useState(0);
  const [status, setStatus] = useState('nada');

  const [edit, setEdit] = useState(false);

  const [id, setId] = useState(null);
  const [idUser, setIdUser] = useState(null);

  const [newAlert, setNewAlert] = useState({
    text: '',
    open: false,
    type: 'info'
  });

  const filterForShippers = (text) => {
    const result = shippers.filter((x) => x.name.toUpperCase().includes(text.toUpperCase()));
    setShippersFilter(result);
  };

  const openAlert = (text = '', type = 'info') => {
    setNewAlert({
      text: text,
      open: !newAlert.open,
      type: type,
    })
  };

  const createUser = () => {
    if (senha !== confirmeSenha || !senha) {
      openAlert('Senha incorreta', 'danger');
    } else if (!email) {
      openAlert('O email é obrigatório', 'danger');
    } else if (!nome) {
      openAlert('O nome é obrigatório', 'danger');
    } else if(tipo < 1 || tipo > 4) {
      openAlert('O tipo é obrigatório', 'danger');
    } else if(status === 'nada') {
      openAlert('O status é obrigatório', 'danger');
    } else {
      apiPortalTransportador.post('/users', {
        email: email,
        name: nome,
        pass: senha,
        type: tipo,
        status: status
      }).then(() => {
        setEmail('');
        setNome('');
        setSenha('');
        setConfirmeSenha('');
        setTipo(0);
        setStatus('nada');

        getAllUsers();
        openAlert('Usuário criado com sucesso!', 'success');

        toggle();
      })
    }
  }

  const editUser = () => {
    if (senha !== confirmeSenha || !senha) {
      openAlert('Senha incorreta', 'danger');
    } else if (!email) {
      openAlert('O email é obrigatório', 'danger');
    } else if (!nome) {
      openAlert('O nome é obrigatório', 'danger');
    } else if(tipo < 1 || tipo > 4) {
      openAlert('O tipo é obrigatório', 'danger');
    } else if(status === 'nada') {
      openAlert('O status é obrigatório', 'danger');
    } else if(!id) {
      openAlert(`O ID: "${id}" não é valído"`, 'danger');
    } else {
      apiPortalTransportador.put('/users', {
        email: email,
        name: nome,
        pass: senha,
        type: tipo,
        status: status,
        id: id,
        idUser: idUser,
      }).then(() => {
        setEmail('');
        setNome('');
        setSenha('');
        setConfirmeSenha('');
        setTipo(0);
        setStatus('nada');
        setId(null);
        setIdUser(null);
        setEdit(false);

        getAllUsers();
        openAlert('Usuário alterado com sucesso!', 'success');

        toggle();
      })
    }
  }

  const getAllUsers = async () => {
    const { data } = await apiPortalTransportador.get('/users');
    data && setUsers(data.reverse());
  }

  const removeUser = () => {
    apiPortalTransportador.delete(`/users/${id}`).then(() => {
      setNome('');
      setId('');

      getAllUsers();
      openAlert('Usuário deletado com sucesso!', 'success');
      toggleDelete();
    }).catch(() => {
      openAlert('Usuário com releacionamentos a serem desvinculado', 'danger');
    })
  };

  const getAllShippers = async () => {
    const { data } = await apiPortalTransportador.get('/shippers');
    data && setShippers(data.reverse());
    data && setShippersFilter(data.reverse());
  };

  const getAllUserShippers = async () => {
    const { data } = await apiPortalTransportador.get('/UsersShippers');
    data && setUserShippers(data.reverse());
  }

  const removeShipperUserRelation = (relationId) => {
    apiPortalTransportador.delete(`/UsersShippers/${relationId}`).then(() => {
      getAllShippers();
      getAllUserShippers();
      getAllUsers();
      
      setNome('');
      setId(null);

      openAlert('Relação de usuário com transportadora removido com sucesso!', 'success');

      setUserShipperIntegration(false);
    })
  }

  const addShipperUserRelation = (shipperId) => {
    apiPortalTransportador.post('/UsersShippers', {
      idUser: id,
      idShipper: shipperId,
    }).then(() => {
      getAllShippers();
      getAllUserShippers();
      getAllUsers();
      
      setNome('');
      setId(null);

      openAlert('Usuário relacionado com sucesso!', 'success');

      setUserShipperIntegration(false);
    })
  }

  const checkIdUserIdShipper = (prop, onlyResp = false) => {
    const result = userShippers.filter((x) => x.idShipper === prop && x.idUser === id);

    if (onlyResp) {
      return result[0] ? true : false
    }

    return (
      <>
        {
          result[0] ? (            
            <button
              className="btn-icon btn-danger"
              style={{ margin: '3px' }}
              onClick={() => {
                removeShipperUserRelation(result[0].id)
              }}
            >
              Remover
            </button>
          ) : (
            <button
              className="btn-icon btn-success"
              style={{ margin: '3px' }}
              onClick={() => {
                addShipperUserRelation(prop)
              }}
            >
              Associar
            </button>
            )
        }
      </>
    )
  }

  const colunms = [
    {
      Header: "Nome",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "E-mail",
      accessor: "email",
      headerClassName: "text-center",
    },
    {
      Header: "Status",
      accessor: "status",
      headerClassName: "text-center",
      Cell: (row) => (
        <p>
          {row.original.status === 'Inativo' ? 'Inativo' : 'Ativo'}
        </p>
      )
    },
    {
      Header: "Tipo",
      accessor: "type",
      headerClassName: "text-center",
      Cell: (row) => (
        <p>
          {row.original.type === 1 && 'Admin'}
          {row.original.type === 2 && 'Transportador'}
          {row.original.type === 3 && 'Tomador'}
          {row.original.type === 4 && 'HUB'}
        </p>
      )
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => (
        <div>
          <button
            className="btn-icon btn-round btn-alert"
            style={{ margin: '3px' }}
            onClick={() => {
              setNome(row.original.name);
              setId(row.original.id);
            setUserShipperIntegration(true);
          }}
          >
            <i className="tim-icons icon-settings-gear-63" />
          </button>
          <button
            className="btn-icon btn-round btn-success"
            style={{ margin: '3px' }}
            onClick={() => {
              setEdit(true);
              setEmail(row.original.email);
              setNome(row.original.name);
              setSenha('');
              setConfirmeSenha('');
              setTipo(row.original.type);
              setStatus(row.original.status === 'Inativo' ? 'Inativo' : 'Ativo');
              setId(row.original.id);
              setIdUser(row.original.idUser);
              toggle();
            }}
          >
            <i className="tim-icons icon-pencil" />
          </button>
          <button
            className="btn-icon btn-round btn-danger"
            style={{ margin: '3px' }}
            onClick={() => {
              setId(row.original.id);
              setNome(row.original.name);

              toggleDelete();
            }}
          >
            <i className="tim-icons icon-trash-simple" />
          </button>
        </div>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const colunmsShippers = [
    {
      Header: "Fantasia",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "CNPJ",
      accessor: "cnpj",
      headerClassName: "text-center",
    },
    {
      Header: "Faz parte",
      acessor: "idShipper",
      headerClassName: "text-center",
      Cell: (row) => (
        <>
          {
            checkIdUserIdShipper(row.original.idShipper, true) ? (<p>Sim</p>) : (<p>Não</p>)
          }
        </>
      )
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => (
        <>
          {checkIdUserIdShipper(row.original.idShipper)}
        </>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const toggleDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  useEffect(() => {
    getAllUsers();
    getAllShippers();
    getAllUserShippers();
  }, []);

  return (
    <Container className="content">
      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2 class="text-dark">{edit ? 'Editar' : 'Novo'} usuário</h2>
        </ModalHeader>
        <ModalBody>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input autoComplete="off" class="form-control text-dark" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Nome do usuário" />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input autoComplete="off" type="email" class="form-control text-dark" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email@email.com" />
          </div>
          <div class="form-group">
            <label for="senha">Senha</label>
            <input autoComplete="off" type="password" class="form-control text-dark" id="senha" value={senha} onChange={(e) => setSenha(e.target.value)} placeholder="************" />
          </div>
          <div class="form-group">
            <label for="confirme">Confirme a senha</label>
            <input autoComplete="off" type="password" class="form-control text-dark" id="confirme" value={confirmeSenha} onChange={(e) => setConfirmeSenha(e.target.value)} placeholder="***********" />
          </div>
          <div class="form-group">
          <label for="type">Tipo</label>
            <select class="form-control text-dark" name="select" id="type" value={tipo} onChange={(e) => setTipo(Number(e.target.value))}>
              <option class="form-control text-dark" disabled value={0} >Selecione</option>
              <option class="form-control text-dark" value={1} >Admin</option>
              <option class="form-control text-dark" value={2} >Transportador</option>
              <option class="form-control text-dark" value={3} >Tomador</option>
              <option class="form-control text-dark" value={4} >HUB</option>
            </select>
          </div>
          <div class="form-group">
          <label for="status">Status</label>
            <select class="form-control text-dark" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option class="form-control text-dark" disabled value="nada">Selecione</option>
              <option class="form-control text-dark" value="Ativo" >Ativo</option>
              <option class="form-control text-dark" value="Inativo" >Inativo</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button type="button" class="btn btn-danger btn-round" style={{margin: '3px'}} onClick={toggle}>Cancelar</button>
            <button type="button" class="btn btn-primary btn-round" style={{margin: '3px'}} onClick={edit ? () => editUser() : () => createUser()}>Salvar</button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openModalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>
          <h2 class="text-dark">{nome}</h2>
        </ModalHeader>
        <ModalBody>
          <h2 class="text-dark">Você realmente quer excluir o usuário {nome}?</h2>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button type="button" class="btn btn-primary btn-round" style={{margin: '3px'}} onClick={toggleDelete}>Não</button>
            <button type="button" class="btn btn-danger btn-round" style={{margin: '3px'}} onClick={removeUser}>Sim</button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Alert color={newAlert.type} isOpen={newAlert.open} toggle={() => openAlert('', '')}>
        {newAlert.text}
      </Alert>

      <Row>
        <h1>Usuários {userShipperIntegration && `- ${nome}`}</h1>
      </Row>
      <Row>
        {!userShipperIntegration && <button type="button" class="btn btn-primary btn-round" onClick={toggle}>Novo</button>}
        {userShipperIntegration && (
          <>
            <button type="button" class="btn btn-primary btn-round" style={{ marginRight: '20px' }} onClick={() => {
              setUserShipperIntegration(false);
              setNome('');
              setId(null);
            }}>Voltar</button>
            <input autoComplete="off" class="form-control" id="confirme" onChange={(e) => filterForShippers(e.target.value)} placeholder="Busque por nome fantasia" />
          </>
        )}
      </Row>
      <ContainerTable>
        <Row>
          <Card>
            <CardBody className="text-center">
              {
                userShipperIntegration ? (
                  <>
                    <ReactTable
                      data={shippersFilter}
                      resizable={true}
                      columns={colunmsShippers}
                      defaultPageSize={5}
                      showPaginationBottom
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum dado a ser exibido"
                      pageText="Página"
                      ofText="de"
                      rowsText="linhas"
                      rowsSelectorText="linhas por página"
                      className="-striped -highlight"
                    />
                  </>
                ) : (
                  <ReactTable
                  data={users}
                  resizable={true}
                  columns={colunms}
                  defaultPageSize={5}
                  showPaginationBottom
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  noDataText="Nenhum dado a ser exibido"
                  pageText="Página"
                  ofText="de"
                  rowsText="linhas"
                  rowsSelectorText="linhas por página"
                  className="-striped -highlight"
                />
                )
              }
            </CardBody>
          </Card>
        </Row>
      </ContainerTable>
    </Container>
  );
};

export default UsuariosPDT;
