import React, { createContext, useContext, useState, useCallback } from "react";

const LegendContext = createContext({});

export const LegendProvider = ({ children }) => {
  const [legendMode, setLegendMode] = useState(false);
  console.log("Initial legendMode:", legendMode); // Adiciona este log para verificar o valor inicial

  const setLegend = useCallback((mode) => {
    setLegendMode(mode); // Mantém o nome consistente com o estado
  }, []);

  return (
    <LegendContext.Provider
      value={{
        legendMode,
        setLegend,
      }}
    >
      {children}
    </LegendContext.Provider>
  );
};

export const useLegend = () => {
  const context = useContext(LegendContext);

  if (!context) {
    throw new Error("useLegend must be used within an AuthProvider");
  }
  return context;
};
