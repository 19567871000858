import Credenciais from "views/Admin/Credenciais";
import Holidays from "views/Admin/Holidays";
import TaxRatesState from "views/Admin/TaxRatesState";
import TaxRatesCounty from "views/Admin/TaxRatesCounty";
import UploadFilesTransporter from "views/Admin/UploadFilesTransporter";
import UploadFilesShipper from "views/Admin/UploadFilesShipper";
import EcommerceIntegration from "views/Admin/EcommerceIntegration";
import Gobid from "views/Admin/Gobid";

const routes = [
  {
    collapse: true,
    state: "relatoriosCollapse",
    name: "Flow",
    icon: "tim-icons icon-bus-front-12",
    layout: "/app",
    authorized: null,
    views: [
      {
        path: "/flw/credenciais",
        name: "Credenciais",
        mini: "CRS",
        component: Credenciais,
        layout: "/app",
        authorized: "Pages.HUB.Flow.Credenciais",
      },
      {
        path: "/flw/holidays",
        name: "Feriados",
        mini: "FER",
        component: Holidays,
        layout: "/app",
        authorized: "Pages.HUB.Flow.Feriados",
      },
      {
        path: "/flw/tax-rates/state",
        name: "Tributos Estaduais",
        mini: "TRE",
        component: TaxRatesState,
        layout: "/app",
        authorized: "Pages.HUB.Flow.TributosEstaduais",
      },
      {
        path: "/flw/tax-rates/county",
        name: "Tributos Municipais",
        mini: "TRM",
        component: TaxRatesCounty,
        layout: "/app",
        authorized: "Pages.HUB.Flow.TributosMunicipais",
      },
      {
        path: "/flw/upload/files/transporter",
        name: "Upload transportador",
        mini: "UFT",
        component: UploadFilesTransporter,
        layout: "/app",
        authorized: "Pages.HUB.Flow.UploadTransportador",
      },
      {
        path: "/flw/upload/files/shipper",
        name: "Upload embarcador",
        mini: "UFE",
        component: UploadFilesShipper,
        layout: "/app",
        authorized: "Pages.HUB.Flow.UploadEmbarcador",
      },
      {
        path: "/flw/iel/ecommerceIntegration",
        name: "Integração Ecommerce",
        mini: "IE",
        component: EcommerceIntegration,
        layout: "/app",
        authorized: "Pages.HUB.Flow.IntegracaoEcommerce",
      },
      {
        path: "/flw/gobid",
        name: "GoBID",
        mini: "BID",
        component: Gobid,
        layout: "/app",
        authorized: "Pages.HUB.Flow.IntegracaoEcommerceLog",
      },
    ],
  },
  {
    collapse: true,
    name: "GoBID",
    icon: "tim-icons icon-trophy",
    state: "relatoriosCollapse",
    authorized: null,
    views: [
      // {
      //   path: "/gobid",
      //   name: "GoBID",
      //   mini: "BID",
      //   component: Gobid,
      //   layout: "/app",
      //   authorized: null,
      // },
    ],
  },
];

export default routes;
