import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ClipLoader } from "react-spinners";

const SweetAlert = forwardRef((props, ref) => {
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  const successAlert = ({
    title = "Good job!",
    onConfirm = hideAlert,
  } = {}) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={onConfirm}
        confirmBtnBsStyle="success"
      >
        A simulação de frete foi realizada com sucesso!
      </ReactBSAlert>
    );
  };

  const errorAlert = ({ title = "Error", onConfirm = hideAlert } = {}) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={onConfirm}
        confirmBtnBsStyle="danger"
      >
        Ocorreu um erro ao simular o frete. Tente novamente.
      </ReactBSAlert>
    );
  };

  const loadingAlert = ({ title = "Simulando Frete" } = {}) => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {}}
        showConfirm={false}
        showCancel={false}
      >
        <div style={{ textAlign: "center" }}>
          <ClipLoader size={50} color={"#1d8cf8"} loading={true} />
          <br />
          <br />
          <h4 className="text-info">Carregando ...</h4>
        </div>
      </ReactBSAlert>
    );
  };

  useImperativeHandle(ref, () => ({
    successAlert,
    errorAlert,
    loadingAlert,
  }));

  return <>{alert}</>;
});

export default SweetAlert;
