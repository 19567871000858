import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import api from "../../../../../services/api";
import { formatDateTime } from "../../../../../utils/formatDate";

const ModalOcorrencia = ({ modal, toggle, notaFiscal }) => {
  const [ocorrencias, setOcorrencias] = useState(null);

  const handleOpenImage = useCallback((e, urlImage) => {
    if (urlImage) {
      window.open(urlImage);
    }
  }, []);

  useEffect(() => {
    async function loadOcorrencias() {
      if (notaFiscal) {
        const result = await api.get("/tracking/ocorrencias", {
          params: {
            idNf: notaFiscal,
          },
        });
        if (result.status === 200) {
          const data = result.data.map((item) => ({
            data_criacao_f: formatDateTime(item.data_criacao),
            ...item,
          }));
          setOcorrencias(data);
        }
      }
    }
    loadOcorrencias();
  }, [notaFiscal]);
  return (
    <Modal isOpen={modal} className="modal-lg">
      <ModalHeader toggle={toggle}>
        <i className="tim-icons icon-delivery-fast" /> Rastreio
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="text-center">
            <h4>Acompanhe seu Pedido</h4>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ alignItems: "center" }}
            className="ml-auto mr-auto text-center"
          >
            <Row>
              <Col md="1" lg="1">
                <h6>CÓDIGO</h6>
              </Col>
              <Col md="3" lg="3">
                <h6>NOME</h6>
              </Col>
              <Col md="4" lg="4">
                <h6>DESCRIÇÃO</h6>
              </Col>
              <Col md="2" lg="2">
                <h6>DATA/HORA</h6>
              </Col>
              <Col md="2" lg="2">
                <h6>ANEXO</h6>
              </Col>
            </Row>
            <hr />
            {ocorrencias
              ? ocorrencias.map((item, index) => (
                  <Row key={index}>
                    <Col md="1" lg="1">
                      <h5>{item.codigo}</h5>
                    </Col>
                    <Col md="3" lg="3">
                      <h5>{item.nome}</h5>
                    </Col>
                    <Col md="4" lg="4">
                      <h5>{item.descricao}</h5>
                    </Col>
                    <Col md="2" lg="2">
                      <h5>{item.data_criacao_f}</h5>
                    </Col>
                    <Col md="2" lg="2">
                      {item.url_image ? (
                        <Button
                          className="btn-link"
                          color="primary"
                          onClick={(e) => handleOpenImage(e, item.url_image)}
                        >
                          <i className="tim-icons icon-attach-87" /> Anexo
                        </Button>
                      ) : (
                        <p>-</p>
                      )}
                    </Col>
                  </Row>
                ))
              : null}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalOcorrencia;
