import React from "react";
import {
  Row,
  Col,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/pt-br";

export default function ModalFilters({
  dataInicial,
  dataFinal,
  open,
  toggle,
  handleDateInicial,
  handleDateFinal,
  onSubmit,
}) {
  const today = moment();
  const dateInitial = moment(dataInicial);
  const initialDateIsValid = (current) => {
    return today.isAfter(current);
  };
  const finalDateIsValid = (current) => {
    if (dateInitial === "") {
      return false;
    }
    return dateInitial.isSameOrBefore(current);
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <div className="modal-header justify-content-center">
        <button
          aria-hidden
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Filtros</h6>
      </div>
      <ModalBody className="text-center">
        <Row>
          <Col>
            <Label>Data inicial</Label>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Selecione uma data Inicial",
                  style: { color: "#222a42", textAlign: "center" },
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                }}
                onChange={handleDateInicial}
                isValidDate={initialDateIsValid}
                value={dataInicial}
                timeFormat={false}
                closeOnSelect
                locale="pt-br"
              />
            </FormGroup>
          </Col>
          <Col>
            <Label>Data final</Label>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Selecione uma data final",
                  style: { color: "#222a42", textAlign: "center" },
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                }}
                onChange={handleDateFinal}
                isValidDate={finalDateIsValid}
                value={dataFinal}
                timeFormat={false}
                closeOnSelect
                locale="pt-br"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Button className="btn-round" color="success" onClick={onSubmit}>
            Filtrar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

ModalFilters.propTypes = {
  dataInicial: PropTypes.instanceOf(moment).isRequired,
  dataFinal: PropTypes.instanceOf(moment).isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleDateInicial: PropTypes.func.isRequired,
  handleDateFinal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
