import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import { ClipLoader } from "react-spinners";

import { useToast } from "../../hooks/toast";

function FileUpload({ api, url, params, paramsFormData, accept, filekey }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(filekey, selectedFile);

      if (paramsFormData) {
        paramsFormData.forEach((param) => {
          formData.append(param.name, param.value);
        });
      }

      const config = {
        headers: { "content-type": "multipart/form-data" },
        params: params,
      };
      const result = await api.post(url, formData, config);
      if (result.status === 200) {
        const message = {
          place: "tr",
          type: "success",
          title: "Sucesso",
          description: "Enviado com sucesso!",
          icon: "tim-icons icon-check-2",
        };
        setSelectedFile();
        setIsSelected();
        addToast(message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSelectedFile();
      setIsSelected();
      const data = error.response.data.errors
        ? error.response.data.errors
        : error;

      const compRender = (
        <div>
          <p>Erro ao enviar! error:</p>
          {data && data.map((item) => <p>{item}</p>)}
        </div>
      );

      const compRenderInvalidFile = (
        <div>
          <p>Erro ao enviar! Arquivo invalido, verifique o arquivo!!</p>
        </div>
      );

      const message = {
        place: "tr",
        type: "danger",
        title: "Error",
        description: data.length > 20 ? compRenderInvalidFile : compRender,
        icon: "tim-icons icon-alert-circle-exc",
      };
      addToast(message);
    }
  };

  return (
    <div>
      <Row>
        <Col className="ml-auto mr-auto">
          <div>
            {Boolean(selectedFile) ? (
              <div>Arquivo selecionado: {selectedFile.name}</div>
            ) : (
              <label>
                Clique aqui para selecionar o arquivo ...
                <input
                  type="file"
                  name="file"
                  onChange={changeHandler}
                  accept={accept}
                  style={{ display: "none" }}
                />
              </label>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto mt-3">
          <Button
            disabled={!isSelected}
            className="btn btn-round btn-success"
            onClick={() => handleSubmission()}
          >
            Enviar
          </Button>
        </Col>
      </Row>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader
            sizeUnit="px"
            size={90}
            color="#1d8cf8"
            loading={loading}
          />
          <br />
          <br />
          <h1 className="text-info" style={{ textAlign: "center" }}>
            Enviando ...
          </h1>
        </div>
      ) : null}
    </div>
  );
}

export default FileUpload;
