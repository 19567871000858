import Tracking from "../views/Public/Tracking";

const routes = [
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
    layout: "/public",
    invisible: true,
  },
];

export default routes;
