import axios from "axios";
import env from "../environment";

const api = axios.create({
  baseURL: env.API_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;
