import React, { useState, useEffect } from "react";
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import ReactTable from "react-table";

import apiPortalTransportador from "services/apiPortalTransportador";

import { ContainerTable, Container, ContainerButtonsModal } from "./styles";

const Companies = () => {
  const [show, setShow] = useState("transportadora");

  const [setUsers] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [companiesShippers, setCompaniesShippers] = useState([]);
  const [companiesUsers, setCompaniesUsers] = useState([]);

  const [shippersFilter, setShippersFilter] = useState(shippers || []);
  const [userFilter, setUserFilter] = useState(shippers || []);

  const [integration, setIntegration] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState();

  const [edit, setEdit] = useState(false);

  const [id, setId] = useState(null);

  const [newAlert, setNewAlert] = useState({
    text: "",
    open: false,
    type: "info",
  });

  const openAlert = (text = "", type = "info") => {
    setNewAlert({
      text: text,
      open: !newAlert.open,
      type: type,
    });
  };

  const createCompanie = () => {
    if (!nomeFantasia) {
      openAlert("O nome fantasia é obrigatorio", "danger");
    } else if (!cnpj) {
      openAlert("O cnpj é obrigatório", "danger");
    } else if (!cep) {
      openAlert("O cep é obrigatório", "danger");
    } else {
      apiPortalTransportador
        .post("/Companies", {
          socialName: razaoSocial,
          fantasyName: nomeFantasia,
          telephone: telefone,
          cellPhone: celular,
          city: cidade,
          zip_Code: cep,
          addrress: logradouro,
          number: numero,
          complement: complemento,
          neighorhood: bairro,
          cnpj: cnpj,
        })
        .then(() => {
          setRazaoSocial("");
          setNomeFantasia("");
          setTelefone("");
          setCelular("");
          setCidade("");
          setCnpj("");
          setLogradouro("");
          setNumero("");
          setComplemento("");
          setBairro("");
          setCnpj("");

          getAllCompanies();
          openAlert("Companhia criada com sucesso!", "success");

          toggle();
        });
    }
  };

  const editUser = () => {
    if (!nomeFantasia) {
      openAlert("O nome fantasia é obrigatorio", "danger");
    } else if (!cnpj) {
      openAlert("O cnpj é obrigatório", "danger");
    } else if (!cep) {
      openAlert("O cep é obrigatório", "danger");
    } else {
      apiPortalTransportador
        .put("/Companies", {
          socialName: razaoSocial,
          fantasyName: nomeFantasia,
          telephone: telefone,
          cellPhone: celular,
          city: cidade,
          zip_Code: cep,
          address: logradouro,
          number: numero,
          complement: complemento,
          neighborhood: bairro,
          cnpj: cnpj,
          idCompany: id,
        })
        .then(() => {
          setRazaoSocial("");
          setNomeFantasia("");
          setTelefone("");
          setCelular("");
          setCidade("");
          setCnpj("");
          setLogradouro("");
          setNumero("");
          setComplemento("");
          setBairro("");
          setCnpj("");
          setId("");
          setEdit(false);

          getAllCompanies();
          openAlert("Usuário alterado com sucesso!", "success");

          toggle();
        });
    }
  };

  const getAllUsers = async () => {
    const { data } = await apiPortalTransportador.get("/Users");
    data && setUsers(data.reverse());
    data && setUserFilter(data.reverse());
  };

  const getAllCompanies = async () => {
    const { data } = await apiPortalTransportador.get("/Companies");
    data && setCompanies(data.reverse());
  };

  const removeCompanie = () => {
    apiPortalTransportador
      .delete(`/Companies/${cnpj}`)
      .then(() => {
        setRazaoSocial("");
        setId("");
        setCnpj("");

        getAllCompanies();
        openAlert("Companhia deletada com sucesso!", "success");
        toggleDelete();
      })
      .catch(() => {
        openAlert(
          "Companhia com relacionamento a serem desvinculado",
          "danger"
        );
      });
  };

  const getAllShippers = async () => {
    const { data } = await apiPortalTransportador.get("/shippers");
    data && setShippers(data);
    data && setShippersFilter(data);
  };

  const getAllCompaniesShippers = async () => {
    const { data } = await apiPortalTransportador.get("/CompaniesShippers");
    data && setCompaniesShippers(data.reverse());
  };

  const getAllCompaniesUsers = async () => {
    const { data } = await apiPortalTransportador.get("/CompaniesUsers");
    data && setCompaniesUsers(data.reverse());
  };

  const removeShipperCompanieRelation = (relationId) => {
    apiPortalTransportador
      .delete(`/CompaniesShippers/${relationId}`)
      .then(() => {
        getAllShippers();
        getAllCompaniesShippers();
        getAllCompanies();
        getAllUsers();

        setRazaoSocial("");
        setId(null);

        openAlert(
          "Relação com transportadora removido com sucesso!",
          "success"
        );

        setIntegration(false);
      });
  };

  const removeUserCompanieRelation = (relationId) => {
    apiPortalTransportador.delete(`/CompaniesUsers/${relationId}`).then(() => {
      getAllShippers();
      getAllCompaniesUsers();
      getAllCompanies();
      getAllUsers();

      setRazaoSocial("");
      setId(null);

      openAlert("Relação com tusuário removido com sucesso!", "success");

      setIntegration(false);
    });
  };

  const addShipperCompanieRelation = (shipperId) => {
    apiPortalTransportador
      .post("/CompaniesShippers", {
        idCompany: id,
        idShipper: shipperId,
      })
      .then(() => {
        getAllShippers();
        getAllUsers();
        getAllCompaniesShippers();
        getAllCompanies();

        setRazaoSocial("");
        setId(null);

        openAlert("Transportadora relacionada com sucesso!", "success");

        setIntegration(false);
      });
  };

  const addUserCompanieRelation = (shipperId) => {
    apiPortalTransportador
      .post("/CompaniesUsers", {
        idCompany: id,
        idCompanyUser: shipperId,
      })
      .then(() => {
        getAllShippers();
        getAllUsers();
        getAllCompaniesUsers();
        getAllCompanies();

        setRazaoSocial("");
        setId(null);

        openAlert("Transportadora relacionada com sucesso!", "success");

        setIntegration(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkIdCompanyIdShipper = (prop, onlyResp = false) => {
    const result = companiesShippers.filter(
      (x) => x.idShipper === prop && x.idCompany === id
    );

    if (onlyResp) {
      return result[0] ? true : false;
    }

    return (
      <>
        {result[0] ? (
          <button
            className="btn-icon btn-danger"
            style={{ margin: "3px" }}
            onClick={() => {
              removeShipperCompanieRelation(result[0].id);
            }}
          >
            Remover
          </button>
        ) : (
          <button
            className="btn-icon btn-success"
            style={{ margin: "3px" }}
            onClick={() => {
              addShipperCompanieRelation(prop);
            }}
          >
            Associar
          </button>
        )}
      </>
    );
  };

  const checkIdCompanyIdUser = (prop, onlyResp = false) => {
    const result = companiesUsers.filter(
      (x) => x.idCompanyUser === prop && x.idCompany === id
    );

    if (onlyResp) {
      return result[0] ? true : false;
    }

    return (
      <>
        {result[0] ? (
          <button
            className="btn-icon btn-danger"
            style={{ margin: "3px" }}
            onClick={() => {
              removeUserCompanieRelation(result[0].idCompanyShipper);
            }}
          >
            Remover
          </button>
        ) : (
          <button
            className="btn-icon btn-success"
            style={{ margin: "3px" }}
            onClick={() => {
              addUserCompanieRelation(prop);
            }}
          >
            Associar
          </button>
        )}
      </>
    );
  };

  const colunms = [
    {
      Header: "Nome social",
      accessor: "socialName",
      headerClassName: "text-center",
    },
    {
      Header: "CNPJ",
      accessor: "cnpj",
      headerClassName: "text-center",
    },
    {
      Header: "CEP",
      accessor: "zip_Code",
      headerClassName: "text-center",
    },
    {
      Header: "Telefone",
      accessor: "telephone",
      headerClassName: "text-center",
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => (
        <div>
          <button
            className="btn-icon btn-round btn-alert"
            style={{ margin: "3px" }}
            onClick={() => {
              setRazaoSocial(row.original.socialName);
              setId(row.original.idCompany);
              setIntegration(true);
            }}
          >
            <i className="tim-icons icon-settings-gear-63" />
          </button>
          <button
            className="btn-icon btn-round btn-success"
            style={{ margin: "3px" }}
            onClick={() => {
              setEdit(true);
              setRazaoSocial(row.original.socialName);
              setNomeFantasia(row.original.fantasyName);
              setTelefone(row.original.telephone);
              setCelular(row.original.cellPhone);
              setCidade(row.original.city);
              setCnpj(row.original.cnpj);
              setLogradouro(row.original.address);
              setNumero(row.original.number);
              setComplemento(row.original.complement);
              setBairro(row.original.neighborhood);
              setId(row.original.idCompany);
              toggle();
            }}
          >
            <i className="tim-icons icon-pencil" />
          </button>
          <button
            className="btn-icon btn-round btn-danger"
            style={{ margin: "3px" }}
            onClick={() => {
              setId(row.original.idCompany);
              setCnpj(row.original.cnpj);
              setRazaoSocial(row.original.socialName);

              toggleDelete();
            }}
          >
            <i className="tim-icons icon-trash-simple" />
          </button>
        </div>
      ),
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const colunmsShippers = [
    {
      Header: "Fantasia",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "CNPJ",
      accessor: "cnpj",
      headerClassName: "text-center",
    },
    {
      Header: "Faz parte",
      acessor: "idShipper",
      headerClassName: "text-center",
      Cell: (row) => (
        <>
          {checkIdCompanyIdShipper(row.original.idShipper, true) ? (
            <p>Sim</p>
          ) : (
            <p>Não</p>
          )}
        </>
      ),
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => <>{checkIdCompanyIdShipper(row.original.idShipper)}</>,
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const colunmsUsers = [
    {
      Header: "Nome",
      accessor: "name",
      headerClassName: "text-center",
    },
    {
      Header: "Email",
      accessor: "email",
      headerClassName: "text-center",
    },
    {
      Header: "Faz parte",
      acessor: "idShipper",
      headerClassName: "text-center",
      Cell: (row) => (
        <>
          {checkIdCompanyIdUser(row.original.id, true) ? (
            <p>Sim</p>
          ) : (
            <p>Não</p>
          )}
        </>
      ),
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: (row) => <>{checkIdCompanyIdUser(row.original.id)}</>,
      headerClassName: "text-center",
      sortable: false,
      filterable: false,
    },
  ];

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const toggleDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  useEffect(() => {
    getAllCompanies();
    getAllShippers();
    getAllUsers();
    getAllCompaniesShippers();
    getAllCompaniesUsers();
  }, []);

  return (
    <Container className="content">
      <Modal isOpen={openModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2 class="text-dark">{edit ? "Editar" : "Nova"} companhia</h2>
        </ModalHeader>
        <ModalBody>
          <div class="form-group">
            <label for="razaoSocial">Razão Social*</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="razaoSocial"
              value={razaoSocial}
              onChange={(e) => setRazaoSocial(e.target.value)}
              placeholder="Razão social"
            />
          </div>
          <div class="form-group">
            <label for="fantasia">Nome fantasia*</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="fantasia"
              value={nomeFantasia}
              onChange={(e) => setNomeFantasia(e.target.value)}
              placeholder="Nome fantasia"
            />
          </div>
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="cnpj"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              placeholder="cnpj"
            />
          </div>
          <div class="form-group">
            <label for="telefone">Telefone</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="telefone"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
              placeholder="00 0 0000-0000"
            />
          </div>
          <div class="form-group">
            <label for="celular">Celular</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="celular"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              placeholder="00 0 0000-0000"
            />
          </div>
          <div class="form-group">
            <label for="cep">CEP</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="cep"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
              placeholder="00000-000"
            />
          </div>
          <div class="form-group">
            <label for="numero">Número</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="numero"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              placeholder="000A"
            />
          </div>
          <div class="form-group">
            <label for="logradouro">Logradouro</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="logradouro"
              value={logradouro}
              onChange={(e) => setLogradouro(e.target.value)}
              placeholder="Rua"
            />
          </div>
          <div class="form-group">
            <label for="cidade">Cidade</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              placeholder="Rua"
            />
          </div>
          <div class="form-group">
            <label for="bairro">Bairro</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              placeholder="Rua"
            />
          </div>
          <div class="form-group">
            <label for="complemento">complemento</label>
            <input
              autoComplete="off"
              class="form-control text-dark"
              id="complemento"
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
              placeholder="Rua"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={edit ? () => editUser() : () => createCompanie()}
            >
              Salvar
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openModalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>
          <h2 class="text-dark">{razaoSocial}</h2>
        </ModalHeader>
        <ModalBody>
          <h2 class="text-dark">
            Você realmente quer excluir a companhia {razaoSocial}?
          </h2>
        </ModalBody>
        <ModalFooter>
          <ContainerButtonsModal>
            <button
              type="button"
              class="btn btn-primary btn-round"
              style={{ margin: "3px" }}
              onClick={toggleDelete}
            >
              Não
            </button>
            <button
              type="button"
              class="btn btn-danger btn-round"
              style={{ margin: "3px" }}
              onClick={removeCompanie}
            >
              Sim
            </button>
          </ContainerButtonsModal>
        </ModalFooter>
      </Modal>

      <Alert
        color={newAlert.type}
        isOpen={newAlert.open}
        toggle={() => openAlert("", "")}
      >
        {newAlert.text}
      </Alert>

      <Row>
        <h1>Companhias {integration && `- ${razaoSocial}`}</h1>
      </Row>
      <Row>
        {!integration && (
          <button
            type="button"
            class="btn btn-primary btn-round"
            onClick={toggle}
          >
            Novo
          </button>
        )}
        {integration && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                class="btn btn-primary btn-round"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  setIntegration(false);
                  setRazaoSocial("");
                  setId(null);
                }}
              >
                Voltar
              </button>
              <div>
                <button
                  onClick={() => setShow("transportadora")}
                  class={
                    show === "transportadora"
                      ? `btn btn-success btn-round`
                      : "btn btn-secundary btn-round"
                  }
                  style={{
                    margin: "5px",
                  }}
                >
                  Vincular transportadora
                </button>
                <button
                  onClick={() => setShow("usuario")}
                  class={
                    show === "usuario"
                      ? `btn btn-success btn-round`
                      : "btn btn-secundary btn-round"
                  }
                  style={{
                    margin: "5px",
                  }}
                >
                  Vincular usuário
                </button>
              </div>
            </div>
            {/* <input autoComplete="off" class="form-control" id="confirme" onChange={ show === 'transportadora' ? (e) => filterForShippers(e.target.value) : (e) => filterForUsers(e.target.value)} placeholder="Busque por nome fantasia" /> */}
          </>
        )}
      </Row>
      <ContainerTable>
        <Row>
          <Card>
            <CardBody className="text-center">
              {integration ? (
                <>
                  {show === "transportadora" ? (
                    <ReactTable
                      data={shippersFilter}
                      resizable={true}
                      columns={colunmsShippers}
                      defaultPageSize={5}
                      showPaginationBottom
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum dado a ser exibido"
                      pageText="Página"
                      ofText="de"
                      rowsText="linhas"
                      rowsSelectorText="linhas por página"
                      className="-striped -highlight"
                    />
                  ) : (
                    <ReactTable
                      data={userFilter}
                      resizable={true}
                      columns={colunmsUsers}
                      defaultPageSize={5}
                      showPaginationBottom
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando..."
                      noDataText="Nenhum dado a ser exibido"
                      pageText="Página"
                      ofText="de"
                      rowsText="linhas"
                      rowsSelectorText="linhas por página"
                      className="-striped -highlight"
                    />
                  )}
                </>
              ) : (
                <ReactTable
                  data={companies}
                  resizable={true}
                  columns={colunms}
                  defaultPageSize={5}
                  showPaginationBottom
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  noDataText="Nenhum dado a ser exibido"
                  pageText="Página"
                  ofText="de"
                  rowsText="linhas"
                  rowsSelectorText="linhas por página"
                  className="-striped -highlight"
                />
              )}
            </CardBody>
          </Card>
        </Row>
      </ContainerTable>
    </Container>
  );
};

export default Companies;
