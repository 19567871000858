import React, { useState, useCallback, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Select from "react-select";
import { GiMoebiusTriangle, GiTruck, GiNestedHexagons } from "react-icons/gi";
import { FaRoute } from "react-icons/fa";
import DateFilterButton from "../DateFilterButton/DateFilterButton";
import moment from "moment";

// reactstrap components
import {
  Button,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  NavItem,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import photo from "assets/img/mike.jpg";
import { useAuth } from "hooks/auth";
import { useCompanies } from "hooks/companies";

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");

  const { user, logout } = useAuth();
  const {
    empresa,
    optionsEmpresa,
    transportadora,
    optionsTransportadora,
    loadCompanies,
    selectEmpresa,
    selectTransportadora,
  } = useCompanies();

  useEffect(() => {
    loadCompanies(user);
  }, [loadCompanies, user]);

  const handleSelectEmpresa = useCallback(
    (empresa) => {
      selectEmpresa(empresa);
    },
    [selectEmpresa]
  );

  const handleSelectTransportadora = useCallback(
    (transportadora) => {
      selectTransportadora(transportadora);
    },
    [selectTransportadora]
  );

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  //function that adds color white/transparent to the navbar on resize (this is for the collapse)
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 993 && collapseOpen) {
        setColor("bg-white");
      } else {
        setColor("navbar-transparent");
      }
    });
  }, [collapseOpen]);

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar
        className={classNames({
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-align-left-2 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Col>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="selectEmpresa"
                value={empresa}
                onChange={(value) => handleSelectEmpresa(value)}
                options={optionsEmpresa}
                placeholder="Embarcadores"
              />
            </Col>
            <Col>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="selectTransportadora"
                value={transportadora || null}
                onChange={(value) => handleSelectTransportadora(value)}
                options={optionsTransportadora}
                placeholder="Transportadoras"
                isClearable={true} // habilita o botão de limpar
              />
            </Col>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  <div className="caret d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-bullet-list-67" />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <DropdownItem header>Trocar Para</DropdownItem>
                  <NavLink tag="li">
                    <DropdownItem style={{ padding: 3 }} className="nav-item">
                      <Button className="btn-icon" color="behance">
                        <GiMoebiusTriangle size={25} />
                      </Button>
                      Flow TMS
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem style={{ padding: 3 }} className="nav-item">
                      <Button className="btn-icon" color="behance">
                        <FaRoute size={25} />
                      </Button>
                      Trackfy TMS
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem style={{ padding: 3 }} className="nav-item">
                      <Button className="btn-icon" color="behance">
                        <GiTruck size={25} />
                      </Button>
                      Portal do Transportador
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem header>Soluções Futuras</DropdownItem>
                  <NavLink tag="li">
                    <DropdownItem style={{ padding: 3 }} className="nav-item">
                      <Button className="btn-icon" color="behance">
                        <GiNestedHexagons size={25} />
                      </Button>
                      GoBid
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem style={{ padding: 3 }} className="nav-item">
                      <Button className="btn-icon" color="behance">
                        <GiMoebiusTriangle size={25} />
                      </Button>
                      Smartdash
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={photo} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={handleLogout}>
                      Sair
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
              <NavItem>
                <DateFilterButton /> {/* Adicione o DateFilterButton aqui */}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
