import React, { createContext, useContext, useCallback, useState } from "react";
import Toast from "../components/Toast";
const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  const addToast = useCallback((message) => {
    setMessage(message);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Toast message={message} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within an AuthProvider");
  }
  return context;
};
