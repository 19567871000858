import React from "react";
import { Row } from "reactstrap";

const Dashboard = () => {
  return (
    <div className="content">
      <Row>
        <h1>Dashboard Transportadora</h1>
      </Row>
    </div>
  );
};

export default Dashboard;
