import currencyFormat from "currency.js";

export const weightFormat = (valor) => {
  return currencyFormat(valor, {
    symbol: "KG ",
    separator: ".",
    precision: 0,
    formatWithSymbol: true,
  }).format();
};
