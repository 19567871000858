import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";

import api from "../../../../../services/api";
import { unitFormat } from "../../../../../utils/unitFormat";

export default function ChartIntegracao() {
  const [integracao, setIntegracao] = useState([]);

  useEffect(() => {
    async function loadIntegracao() {
      try {
        const arrayI = [];

        // media de valores por mes
        const responseIntegracao = await api.get(
          "/dashboard/cockpit/integracao_simulacao"
        );

        if (responseIntegracao.status === 200) {
          arrayI.push({
            titulo: "Nota Fiscal",
            subtitulo: "Pendente de Integração",
            valor: unitFormat(responseIntegracao.data.integrationNfe),
            icon: (
              <div className="info-icon text-center icon-warning">
                <i className="tim-icons icon-paper" />
              </div>
            ),
          });
          arrayI.push({
            titulo: "CTe",
            subtitulo: "Pendente de Integração",
            valor: unitFormat(responseIntegracao.data.integrationCte),
            icon: (
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-bus-front-12" />
              </div>
            ),
          });
          arrayI.push({
            titulo: "Fatura",
            subtitulo: "Pendente de Integração",
            valor: unitFormat(responseIntegracao.data.integrationFatura),
            icon: (
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-credit-card" />
              </div>
            ),
          });
          arrayI.push({
            titulo: "Ocorrências",
            subtitulo: "Pendente de Integração",
            valor: unitFormat(responseIntegracao.data.integrationOcorrencia),
            icon: (
              <div className="info-icon text-center icon-danger">
                <i className="tim-icons icon-app" />
              </div>
            ),
          });
        }

        setIntegracao(arrayI);
      } catch (error) {
        setIntegracao([]);
      }
    }
    loadIntegracao();
  }, []);

  return (
    <Row>
      {integracao.map((integration, index) => (
        <Col lg="3" md="6" key={index.toString()}>
          <Card
            style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
            className="card-stats"
          >
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    {integration.icon}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <CardTitle tag="h4">{integration.titulo}</CardTitle>
                    <CardTitle tag="h3">{integration.valor}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="tim-icons icon-double-right" />
                {integration.subtitulo}
              </div>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
