import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";
import "../../../../../assets/css/cards.css";

import api from "../../../../../services/api";
import { formatCurrency } from "../../../../../utils/formatCurrency";

export default function ChartFaturasResumo() {
  const [totalizadores, setTotalizadores] = useState([]);

  useEffect(() => {
    async function loadTotalizadores() {
      try {
        const arrayT = [];

        const responseTotalizadores = await api.get(
          "/dashboard/auditoria/resumo/faturas"
        );

        if (responseTotalizadores.status === 200) {
          arrayT.push({
            titulo: "Faturas Atrasadas",
            subtitulo: "Backlog",
            valor: formatCurrency(responseTotalizadores.data.valorBacklogFat),
            gradientClass: "gradient-backlog",
          });
          arrayT.push({
            titulo: "Faturas a Vencer",
            subtitulo: "Online",
            valor: formatCurrency(responseTotalizadores.data.valorOnlineFat),
            gradientClass: "gradient-online",
          });
          arrayT.push({
            titulo: "Faturas Auditadas",
            subtitulo: "Liberadas nos últimos 30 dias",
            valor: formatCurrency(responseTotalizadores.data.valorAuditFat),
            gradientClass: "gradient-auditadas",
          });
        }

        setTotalizadores(arrayT);
      } catch (error) {
        setTotalizadores([]);
      }
    }
    loadTotalizadores();
  }, []);

  return (
    <Row>
      {totalizadores.map((totalizador, index) => (
        <Col lg="4" md="6" key={index.toString()}>
          <Card
            style={{
              boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)",
            }}
            className={`card-stats ${totalizador.gradientClass}`}
          >
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="numbers">
                    <CardTitle tag="h4">{totalizador.titulo}</CardTitle>
                    <CardTitle tag="h3">{totalizador.valor}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="tim-icons icon-calendar-60" />
                {totalizador.subtitulo}
              </div>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
