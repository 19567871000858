import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import api from "../../../../../services/api";
import CustomTable from "../../../../../components/CustomTableChart";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { percentFormat } from "../../../../../utils/percentFormat";

export default function ChartResumoAuditoria() {
  const [data, setData] = useState([]); // Inicializado como array vazio
  const [columns, setColumns] = useState([]); // Inicializado como array vazio
  const [loading, setLoading] = useState(true); // Mantém o loading como true

  useEffect(() => {
    async function loadCharts() {
      setLoading(true);
      try {
        const response = await api.get("/dashboard/custo/resumo-divergencia");

        if (response.status === 200) {
          const result = response.data;

          const periods = [
            "JAN",
            "FEV",
            "MAR",
            "ABR",
            "MAI",
            "JUN",
            "JUL",
            "AGO",
            "SET",
            "OUT",
            "NOV",
            "DEZ",
            "MED",
          ];

          const fixedColumns = [
            { dataField: "name", text: "#" },
            ...periods.map((period) => ({ dataField: period, text: period })),
          ];

          // Processar os dados para structuredData
          const structuredData = [];

          // Mapear os campos para nomes amigáveis
          const fieldNames = {
            qtd_cte: "Quantidade CTe",
            qtd_divergencias: "Quantidade Divergências",
            percentual: "Percentual (%)",
            valor_divergencia: "Valor Divergências (R$)",
          };

          // Iterar sobre as chaves do objeto result
          for (const [key, value] of Object.entries(result)) {
            const row = { name: fieldNames[key] || key };

            periods.forEach((period) => {
              let cellValue = value[period] || 0;

              // Formatar os valores conforme necessário
              if (key === "percentual") {
                cellValue = percentFormat(cellValue);
              } else if (key === "valor_divergencia") {
                cellValue = formatCurrency(cellValue);
              }

              row[period] = cellValue;
            });

            structuredData.push(row);
          }

          setColumns(fixedColumns);
          setData(structuredData);
        } else {
          console.error("Erro ao carregar os dados:", response);
          setColumns([]);
          setData([]);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
        setColumns([]);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, []);

  return (
    <div className="content">
      <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
        <CardBody>
          <CustomTable
            data={data}
            columns={columns}
            tableName="Resumo de Divergências"
            loading={loading}
          />
        </CardBody>
      </Card>
    </div>
  );
}
