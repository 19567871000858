import React from "react";
import { Route as ReactDOMRoute, Redirect } from "react-router-dom";

import { useAuth } from "../hooks/auth";

const Route = ({ component: Component, isPrivate, isPublic, ...rest }) => {
  const { user } = useAuth();
  let signed = false;

  if (user) {
    signed = true;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/auth/login" {...rest} />;
  }
  if (signed && !isPrivate) {
    return <Redirect to="/app/dashboard" {...rest} />;
  }
  return (
    <ReactDOMRoute {...rest} render={(props) => <Component {...props} />} />
  );
};

export default Route;
