import React, { createContext, useContext, useState, useCallback } from "react";

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);

  const setTheme = useCallback((theme) => {
    setDarkMode(theme);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within an AuthProvider");
  }
  return context;
};
